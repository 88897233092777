import React from "react";
import { AccessControlTypes, PSUTypes } from "types";
import { Button, ButtonProps, Popconfirm } from "antd";
import { useActionButton } from "components/utils/hooks/useActionButton";
import RoleGuard from "../standalone/role-guard"; 
import queryClient, { useMutation } from "components/utils/queryClient";

type DeleteButtonProps = {
  resource: PSUTypes;
  refetch?: Array<string>
} & Omit<ButtonProps, "resource">;

export default function DeleteButton({
  resource,
  refetch,
  ...props
}: DeleteButtonProps) {
  const { getButtonProps } = useActionButton();
  const { mutateAsync, isLoading } = useMutation();

  return (
    <RoleGuard
      requiredRoles={["Administrator", "Operator"]}
      requiredAccessControls={AccessControlTypes.Delete}
      accessControls={resource?.accessControls}
    >
      <Popconfirm
        title="Are you sure ?"
        color="red"
        onConfirm={() =>
          mutateAsync(
            {
              key: resource?.resourceInfo?.self,
              action: "delete",
            },
            {
              onSuccess: () => {
                if (resource?.resourceInfo?.schemaName === "license") {
                  queryClient.resetQueries("/license/feature");
                  queryClient.resetQueries("/license", {
                    active: true,
                    inactive: true,
                    stale: true,
                  });
                }
                if (resource?.resourceInfo?.schemaName === "role") {
                  queryClient.refetchQueries("/role", {
                    active: true,
                    inactive: true,
                    stale: true,
                  });
                  queryClient.refetchQueries(
                    `${resource?.resourceInfo?.parent}/identity`,
                    { active: true, inactive: true, stale: true }
                  );
                }
                queryClient.refetchQueries(resource?.resourceInfo?.parent);

                if (refetch)
                {
                  refetch.forEach(x => queryClient.refetchQueries(x));
                }
              },
            }
          )
        }
      >
        <Button
          {...props}
          {...getButtonProps({
            action: "delete",
          })}
          loading={isLoading}
        />
      </Popconfirm>
    </RoleGuard>
  );
}
