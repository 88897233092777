import { JobFeedback } from "../../../types";
import { CustomSchema } from "./types";

export const feedbackSchema: CustomSchema<JobFeedback>[] = [
  {
    name: "data",
    label: "Feedback",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Feedback can't be empty."
    }]
  },
];
