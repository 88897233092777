import React from "react";
import { Button, ButtonProps, message, Tooltip } from "antd";
import RoleGuard from "../standalone/role-guard";
import { useClipboard } from "use-clipboard-hook";
import { CopyFilled } from "@ant-design/icons";

type CopyButtonProps = {
  text: string
} & Omit<ButtonProps, "resource">;

export default function CopyButton({ text, ...props }: CopyButtonProps) {
  const { copy } = useClipboard({
    onSuccess: () => message.success(`Copied.`),
  });

  return (
    <RoleGuard requiredRoles={["Administrator"]}>
      <Tooltip title="Copy">
      <Button
        type="text"
        icon={<CopyFilled />}
        {...props}
        onClick={() => copy(text)}
      />
      </Tooltip>
    </RoleGuard>
  );
}
