import { Folder } from "../../../types";
import { CustomSchema } from "./types";

export const folderSchema: CustomSchema<Folder>[] = [
    {
        name: "name",
        label: "name",
        component: "input",
        tooltip: "Name of the folder",
        required: true,
        rules: [{
            required: true,
            message: "Path can't be empty."
        }]
    },
];
