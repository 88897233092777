/// This is where we should start putting things that require a switch between the desktop\web version so it's all in one place
import React, { ReactNode } from "react";
import { presetPrimaryColors } from "@ant-design/colors";
import { Badge, Tag, Tooltip, Typography } from "antd";
import { useInterval } from "ahooks";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
  MinusCircleOutlined,
  FieldTimeOutlined,
  PoweroffOutlined,
  LoadingOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import {
  DashboardStatus,
  JobStatus,
  JobStreamOutput,
  MethodType,
  Variable,
  GitStatusResult,
  DashboardLog,
  Job,
  JobError,
  Trigger,
} from "../../types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import JobRunTime from "components/ui/jobRuntimeInterval";
import queryClient from "./queryClient";
import { Link } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

/**
 * Set the color for the current method.
 * @param method
 * @example setMethodColor("GET")
 * @description Will return the method text with the correct color.
 */
export function setMethodColor(method: MethodType | undefined) {
  switch (method) {
    case "GET":
      return presetPrimaryColors["blue"];
    case "PATCH":
      return presetPrimaryColors["blue"];
    case "OPTIONS":
      return presetPrimaryColors["blue"];
    case "POST":
      return presetPrimaryColors["green"];
    case "DELETE":
      return presetPrimaryColors["red"];
    case "PUT":
      return presetPrimaryColors["orange"];
  }
}

/**
 * Set variable tag base if it a secret or not.
 * @param variable
 * @example setVariableTag(variable)
 * @description Set variable tag base if it a secret or not.
 */
export function setVariableTag(variable: Variable | undefined) {
  switch (variable.vault !== null) {
    case true:
      return <Tag color={presetPrimaryColors["blue"]}>SECRET</Tag>;
    case false:
      return <Tag color={presetPrimaryColors["purple"]}>PUBLIC</Tag>;
  }
}

export declare const LicenseName: [
  "PowerShellUniversalAutomation",
  "UniversalAutomation",
  "UniversalDashboard",
  "UniversalDashboardDashboard",
  "PowerShellUniversalDashboard",
  "PowerShellUniversalApi",
  "PowerShellUniversal",
  "Universal",
  "UniversalPersonal"
];

export declare type LicenseType = typeof LicenseName[number];

/**
 * Get license text base on type.
 * @param license
 * @example setLicenseName("PowerShellUniversal")
 * @description Will return the license name base on license type.
 */
export function setLicenseName(license: LicenseType | undefined) {
  switch (license) {
    case "PowerShellUniversalAutomation":
      return "Universal Automation";
    case "UniversalAutomation":
      return "Universal Automation";
    case "UniversalDashboard":
      return "Universal Dashboard and API";
    case "UniversalDashboardDashboard":
      return "Universal Dashboard and API";
    case "PowerShellUniversalDashboard":
      return "Universal Dashboard";
    case "PowerShellUniversalApi":
      return "Universal API";
    case "PowerShellUniversal":
      return "Universal";
    case "UniversalPersonal":
      return "Universal Lite";
    case "Universal":
      return "Universal";
  }
}

/**
 * Set the power button icon color base on the dashboard current status.
 * @param status
 * @description Will return the currect icon color base on the current dashboard status.
 * @example setPowerIconColor(0)
 */
export function setPowerIconColor(status: number) {
  switch (status) {
    case 0:
      return (
        <Tooltip title="Dashboard Is Stopped" color="red">
          <PoweroffOutlined
            style={{
              color: presetPrimaryColors["red"],
            }}
          />
        </Tooltip>
      );
    case 1:
      return (
        <Tooltip title="Dashboard Is Running" color="blue">
          <PoweroffOutlined
            style={{
              color: presetPrimaryColors["blue"],
            }}
          />
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip title="Dashboard In Debug Mode" color="cyan">
          <PoweroffOutlined
            style={{
              color: presetPrimaryColors["cyan"],
            }}
          />
        </Tooltip>
      );
  }
}

/**
 * Return a Badge with text and color
 * @param {DashboardStatus} status
 * @example setStatusColor(1)
 * @description Will return ant-design Badge component base on status number.
 * @author Alon Gvili
 */
export function setStatusColor(status: DashboardStatus | undefined) {
  switch (status) {
    case 0:
      return <Badge status="error" text="Stopped" />;
    case 1:
      return <Badge status="success" text="Started" />;
    case 2:
      return <Badge status="error" text="StartFailed" />;
    case 3:
      return <Badge status="processing" text="Starting" />;
    case 4:
      return <Badge status="warning" text="Debugging" />;
  }
}

/**
 *
 * @description Will return ant-design tag component base on status number.
 * @param status
 * @example setStatusTag(1)
 * @author Alon Gvili
 */
export function setStatusTag(status: number) {
  switch (status) {
    case 0:
      return (
        <Tag icon={<FieldTimeOutlined />} color="default">
          queued
        </Tag>
      );
    case 1:
      return (
        <Tag icon={<SyncOutlined spin />} color="blue">
          running
        </Tag>
      );
    case 2:
      return (
        <Tag icon={<CheckCircleOutlined />} color="green">
          success
        </Tag>
      );
    case 3:
      return (
        <Tag icon={<CloseCircleOutlined />} color="red">
          failed
        </Tag>
      );
    case 4:
      return (
        <Tag icon={<ClockCircleOutlined />} color="gold">
          waiting for feedback
        </Tag>
      );
    case 5:
      return (
        <Tag icon={<MinusCircleOutlined />} color="default">
          cancelled
        </Tag>
      );
    case 6:
      return (
        <Tag icon={<SyncOutlined spin />} color="default">
          Canceling
        </Tag>
      );
    case 9:
      return (
        <Tag icon={<ClockCircleOutlined />} color="red">
          timed out
        </Tag>
      );
  }
}

export function setGitStatusIcon(status: GitStatusResult) {
  switch (status) {
    case 0:
      return (
        <Tag icon={<CaretRightOutlined />} color="processing">
          Processing
        </Tag>
      );
    case 1:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Success
        </Tag>
      );
    case 2:
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="error">
          Failed
        </Tag>
      );
    default:
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          Initializing
        </Tag>
      );
  }
}

/**
 *
 * @description Will return ant-design tag component base on status number.
 * @param status
 * @example setStatusTag(1)
 * @author Alon Gvili
 */
export function setStatusIcon(status: JobStatus, size: number = 16) {
  switch (status) {
    case 0: // Queue
      return (
        <FieldTimeOutlined
          size={size}
          style={{
            color: presetPrimaryColors["gray"],
          }}
        />
      );
    case 1: // Running
      return (
        <LoadingOutlined
          spin
          size={size}
          style={{ color: presetPrimaryColors["blue"] }}
        />
      );
    case 2: // Finish
      return (
        <CheckCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["lime"],
          }}
        />
      );
    case 3: // Faild
      return (
        <CloseCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["red"],
          }}
        />
      );
    case 4: // Waiting for feedback
      return (
        <ClockCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["yellow"],
          }}
        />
      );
    case 5: // canceled
      return (
        <ExclamationCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["gray"],
          }}
        />
      );
    case 6: // canceling
      return (
        <ExclamationCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["gray"],
          }}
        />
      );
    case 9: // timedout
      return (
        <ClockCircleOutlined
          size={size}
          style={{
            color: presetPrimaryColors["red"],
          }}
        />
      );
  }
}

/**
 *
 * @description Will return job status as text ( word ) base on job status number.
 * @param status
 * @example getJobStatusText(1)
 * @author Alon Gvili
 */
export function getJobStatusText(status: JobStatus) {
  switch (status) {
    case 0:
      return "Queued";
    case 1:
      return "Running";
    case 2:
      return "Completed";
    case 3:
      return "Failed";
    case 4:
      return "WaitingOnFeedback";
    case 5:
      return "Canceled";
    case 6:
      return "Canceling";
    case 7:
      return "Historical";
    case 8:
      return "Active";
    case 9:
      return "Timed Out";
  }
}

/*
 * Get trigger event name.
 * @param eventType
 * @example setTriggerEventName(0)
 * @description Will return the trigger event name base on the event type number.
 */
export function setTriggerEventName(eventType: number) {
  switch (eventType) {
    case 0:
      return "JobCanceled";
    case 1:
      return "JobFailed";
    case 2:
      return "JobCompleted";
    case 3:
      return "JobStarted";
    case 4:
      return "JobFeedbackRequested";
    case 5:
      return "ServerStarted";
    case 6:
      return "ServerStopped";
    case 7:
      return "DashboardStarted";
    case 8:
      return "DashboardStopped";
  }
}

// Invoke one function against every item in an array
export function actionOnItems(action, ...items: any[]): any[] {
  return items.map((item) => action(item));
}

// Invoke many functions against one item.
export function actionsOnItem(item, ...actions) {
  return actions.map((action) => action(item));
}

// Invoke many functions against many items.
export const actionsOnItems = (...items) => (...actions) => {
  return items.map((item) => actions.map((action) => action(item)));
};

export class Utils {
  getVersion(props: any): string {
    var version = props.version;
    return version;
  }

  getSetting(name: string) {
    return null;
  }

  getUpdateAvailable() {
    return false;
  }
}

export function capitalize(
  text: undefined | string | string[] | ((value: string) => string)
) {
  if (typeof text !== "string") return "";
  if (text === undefined) return "";
  return text.charAt(0).toUpperCase() + text.slice(1);
}

function formatMsgType(type: number) {
  switch (type) {
    case 0:
      return "";
    case 1:
      return "[verbose]";
    case 2:
      return "[debug]";
    case 3:
      return "[warning]";
    case 4:
      return "[error]";
  }
}

export function parseJobLog(log: JobStreamOutput[]) {
  if (log === undefined || log === null) return "# Waiting for job data...";
  let logEntries = "";
  log.forEach((entry) => {
    if (entry?.Type === 4) {
      queryClient.setQueryData(
        `/job/${entry?.JobId}/errors`,
        (old: JobError[]) => {
          if (old === undefined)
            return [{ message: entry?.Data, timestamp: entry?.Timestamp }];
          return [
            ...old,
            { message: entry?.Data, timestamp: entry?.Timestamp },
          ];
        }
      );
    }
    logEntries += `${formatDate(entry?.Timestamp)} ${formatMsgType(
      entry.Type
    )} ${[entry.Data]} \r\n`;
  });
  return logEntries;
}
export function parseJobLogNoTimestamp(log: JobStreamOutput[]) {
  if (log === undefined || log === null) return "# Waiting for job data...";
  let logEntries = "";
  log.forEach((entry) => {
    if (entry?.Type === 4) {
      queryClient.setQueryData(
        `/job/${entry?.JobId}/errors`,
        (old: JobError[]) => {
          if (old === undefined)
            return [{ message: entry?.Data, timestamp: entry?.Timestamp }];
          return [
            ...old,
            { message: entry?.Data, timestamp: entry?.Timestamp },
          ];
        }
      );
    }
    logEntries += `${formatMsgType(entry.Type)} ${[entry.Data]} \r\n`;
  });
  return logEntries;
}

export function parseJobPipeline(output: string) {
  if (output === undefined || output === null) return null;
  return JSON.parse(output);
}

export function parseDashboardLog(log: DashboardLog) {
  if (log === undefined || log === null) return "# Waiting for log data...";
  let logEntries = "";
  try {
    let entries = JSON.parse(log?.log);
    entries.reverse().forEach(
      (entry) =>
        (logEntries += `${formatDate(entry?.Timestamp)} ${[entry.Data]} \r\n`)
    );
  }
  catch
  {

  }

  return logEntries;

}

export function parseDashboardLogNoTimestamp(log: DashboardLog) {
  if (log === undefined || log === null) return "# Waiting for log data...";
  let logEntries = "";
  try {
    let entries = JSON.parse(log?.log);
    entries.reverse().forEach((entry) => (logEntries += `${[entry.Data]} \r\n`));
  }
  catch
  {
  }

  return logEntries;
}

export function useJobInterval() {
  const [count, setCount] = React.useState(0);
  useInterval(() => {
    setCount(count + 1);
  }, 1000);
  return <div>count: {count}</div>;
}

export function setModalTitle(
  title: ReactNode,
  disabled: boolean,
  setDisabled: (value: boolean) => void
) {
  return (
    <div
      style={{
        width: "100%",
        cursor: "move",
      }}
      onMouseOver={() => {
        if (disabled) {
          setDisabled(false);
        }
      }}
      onMouseOut={() => {
        setDisabled(true);
      }}
      onFocus={() => { }}
      onBlur={() => { }}
    >
      {title}
    </div>
  );
}

export const callAll = (...fns) => (...args) =>
  fns.forEach((fn) => fn && fn(...args));

export function hasTagProperty(record) {
  if (!record) return false;
  return Object?.keys(record)?.includes("tag");
}

export function hasTags(record) {
  if (!record) return false;
  return hasTagProperty(record) && record?.tag?.length > 0;
}

export function formatDate(date: Date, format?: string) {
  return dayjs(date).format(format || "lll");
}

export function getPeriodString(value: string) {
  const period = Number.parseInt(value);
  const days = Math.floor(period / 86400);
  const hours = Math.floor(period / 3600);
  const minutes = Math.floor(period / 60);
  const seconds = period % 60;
  return `${days}.${hours}:${minutes}:${seconds}.0000000`;
}

export function generateJobDescription(job: Job) {
  let isChedule = job?.scheduleId !== 0 ? "scheduled" : "";
  let userName = job?.identity?.name;

  if (job?.triggered) {
    queryClient.prefetchQuery("/trigger");
    // queryClient.refetchQueries("/trigger", {inactive: true});

    let triggerInfo = queryClient
      .getQueryData<Trigger[]>("/trigger")
      ?.find((trigger) => trigger?.name === job?.trigger);

    if (triggerInfo) {
      let resourceLink = triggerInfo?.dashboard ? (
        <Link to={`/admin/dashboards/${triggerInfo?.dashboard}`}>
          <Typography.Link>{triggerInfo?.dashboard}</Typography.Link>
        </Link>
      ) : triggerInfo?.script ? (
        <Link to={`/admin/automation/scripts/${job?.scriptFullPath}`}>
          <Typography.Link>{triggerInfo?.script}</Typography.Link>
        </Link>
      ) : null;

      return (
        <Typography>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            job was triggered by{" "}
            <Typography.Text strong>{triggerInfo?.name}</Typography.Text> when{" "}
            <Typography.Text strong>
              {setTriggerEventName(parseInt(triggerInfo?.eventType))}
            </Typography.Text>{" "}
            occurred {resourceLink !== null ? "on " : ""}
          </Typography.Text>
          {resourceLink}
          {job?.environment ? <><Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {" "}in the{" "}
          </Typography.Text>
            <Link to={`/admin/settings/environments`}>
              <Typography.Link>{job?.environment} environment</Typography.Link>
            </Link></> : <></>}
          {job?.credential ? <React.Fragment>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {" "}as{" "}
            </Typography.Text>
            <Link to={`/admin/automation/variables`}>
              <Typography.Link>{job?.credential}</Typography.Link>
            </Link>
          </React.Fragment> : <React.Fragment />}
        </Typography>
      );
    }
  }

  if (isChedule) {
    return (
      <Typography>
        {job?.retryCount > 0 && (
          <>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              retry {job.retryCount + " of job "}
            </Typography.Text>
            <Link to={`/admin/automation/job/${job?.parentJob?.id}`}>
              <Typography.Link>{job?.parentJob?.id}</Typography.Link>
            </Link>
          </>
        )}
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          scheduled by{" "}
        </Typography.Text>
        <Link to={`/admin/security/identities/${job?.identity?.id}`}>
          <Typography.Link>{userName}</Typography.Link>
        </Link>
        {job?.environment ? <><Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {" "}in the {" "}
        </Typography.Text>
          <Link to={`/admin/settings/environments`}>
            <Typography.Link>{job?.environment} environment</Typography.Link>
          </Link></> : <></>}
        {job?.credential ? <React.Fragment>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {" "}as{" "}
          </Typography.Text>
          <Link to={`/admin/automation/variables`}>
            <Typography.Link>{job?.credential}</Typography.Link>
          </Link>
        </React.Fragment> : <React.Fragment />}
      </Typography>

    );
  }

  return (
    <Typography>
      {job?.retryCount > 0 && (
        <>
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            retry {job.retryCount + " of job "}
          </Typography.Text>
          <Link to={`/admin/automation/job/${job?.parentJob?.id}`}>
            <Typography.Link>{job?.parentJob?.id + " "}</Typography.Link>
          </Link>
        </>
      )}
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
        run manually by{" "}
      </Typography.Text>
      <Link to={`/admin/security/identities/${job?.identity?.id}`}>
        <Typography.Link>{userName}</Typography.Link>
      </Link>
      {job?.environment ? <><Typography.Text type="secondary" style={{ fontSize: 12 }}>
        {" "}in the{" "}
      </Typography.Text>
        <Link to={`/admin/settings/environments`}>
          <Typography.Link>{job?.environment} environment</Typography.Link>
        </Link></> : <></>}
      {job?.credential ? <React.Fragment>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {" "}as{" "}
        </Typography.Text>
        <Link to={`/admin/automation/variables`}>
          <Typography.Link>{job?.credential}</Typography.Link>
        </Link>
      </React.Fragment> : <React.Fragment />}
    </Typography>
  );
}

export function generateDateInfo(record: Job) {
  if (!record) return "";
  if (record.status === JobStatus.Queued) return "";
  const start = dayjs(record?.startTime);
  return dayjs().to(start);
}

export function generateJobTitle(job: Job) {
  return (
    <Link
      style={{
        color: "inherit",
        fontFamily: "inherit",
        fontSize: "inherit",
      }}
      to={`/admin/automation/jobs/${job.id}`}
    >
      {job.id}
    </Link>
  );
}

export function generateTimeInfo(record: Job) {
  if (record?.status === JobStatus.Queued) return "";

  if (
    (record?.endTime?.toString() === "0001-01-01T00:00:00" && record?.status === JobStatus.Running)) {
    return <JobRunTime job={record} />;
  }
  if (
    record?.endTime.toString() !== "0001-01-01T00:00:00" &&
    record?.status === JobStatus.Completed
  ) {
    const start = dayjs(record?.startTime);
    const end = dayjs(record?.endTime);
    let duration = dayjs.duration(end.diff(start));
    let hr = duration.hours();
    let min = duration.minutes();
    let sec = duration.seconds();

    return `${hr !== 0 ? `${hr}h` : ""} ${min !== 0 ? `${min}m` : ""} ${sec !== 0 ? `${sec}s` : ""
      }`;
  } else return "-";
}

export function getnerateTreeData(data) {
  if (Array.isArray(data)) {
    let hasTitle = Object.keys(data[0]).includes("Title");
    let hasName = Object.keys(data[0]).includes("Name");
    let hasLabel = Object.keys(data[0]).includes("Label");

    if (hasTitle) {
      return data.map((item, index) => ({
        ...item,
        key: `${index}-${item?.title}`,
      }));
    }
    if (hasLabel) {
      return data.map((item, index) => ({
        ...item,
        title: item?.Label,
        key: `${index}-${item?.Label}`,
      }));
    }
    if (hasName) {
      return data.map((item, index) => ({
        ...getnerateTreeData([item]),
        title: item?.Name,
        key: `${index}-${item?.Name}`,
      }));
    }
    return data.map((item, index) => ({
      ...item,
      title: 'object',
      key: `${index}-object`,
    }));
  }
}
