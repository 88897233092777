import { createContext } from "react";
import { Accessible, Feature, Settings, Stats, Update, Sso } from "../../../types"

export type AppReducerState = {
  stats: Stats
  version: string
  update: Update,
  settings: Settings
  userData?: Accessible
  sso: Sso,
  licensedFeatures?: Feature[],
  licensed?: (feature: Feature) => boolean,
  isUserDataLoading: boolean,
  isSsoDataLoading: boolean
};

export type ContextTypeProps = AppReducerState

export const initState = {
  stats: {} as Stats,
  version: '',
  update: {} as Update,
  userData: {} as Accessible,
  settings: {} as Settings,
  sso: {} as Sso,
  licensedFeatures: [Feature.Api, Feature.Automation, Feature.Dashboard],
  licensed: () => false,
  isUserDataLoading: true,
  isSsoDataLoading: true
};

export const AppContext = createContext<AppReducerState>({
  ...initState
});
