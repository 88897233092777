import { Role } from "../../../types";
import { CustomSchema } from "./types";

export const frameworkSchema: CustomSchema<Role>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    tooltip:"Enter the name for this framework.",
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "version",
    label: "Version",
    component: "input",
    rules: [{
      required: true,
      message: "version can't be empty."
    }]
  },
  {
    name: "path",
    label: "Path",
    component: "input",
    rules: [{
      required: true,
      message: "path can't be empty."
    }]
  },
];
