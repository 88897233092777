import "../../Tables.module.css";
import React from "react";
import { Col, Row, Tooltip } from "antd";
import useContentProvider from "components/context/content/Hooks";
import Loader from "components/utils/Loader";
import ContentProvider from "components/context/content/Provider";
import { callAll } from "components/utils/utils";
import {
  PicCenterOutlined,
  PicLeftOutlined,
  PicRightOutlined,
} from "@ant-design/icons";

interface PageContextProps {
  resource?: any | null | undefined;
}

const PageContext = React.createContext<PageContextProps>(null);

export default function Page({
  children,
  resource,
}: {
  children: any;
  resource?: any | null | undefined;
}) {
  return (
    <PageContext.Provider value={{ resource }}>
      <ContentProvider>
        <Loader spinning={!resource}>{children}</Loader>
      </ContentProvider>
    </PageContext.Provider>
  );
}

function PageHeader({ children }) {
  return (
    <Row gutter={[24, 24]} style={{ marginBottom: 24 }}>
      <Col span={24}>{children}</Col>
    </Row>
  );
}

function PageContentBase({ children }) {
  return <Row gutter={[24, 24]}>{children}</Row>;
}

function PageContent({ children }) {
  return (
    <Row gutter={[24, 24]}>
      <Col span={24} style={{ height: "100%" }}>
        {children}
      </Col>
    </Row>
  );
}

function LeftPanel({ children }) {
  const [{ left }] = useContentProvider();

  return (
    <Col span={left.colSpan} style={{ height: "100%" }}>
      {children}
    </Col>
  );
}

function RightPanel({ children }) {
  const [{ right }] = useContentProvider();

  return (
    <Col span={right.colSpan} style={{ height: "100%" }}>
      {children}
    </Col>
  );
}

function LeftPanelSizeButton({ children: child }) {
  const [{ left }, dispatch] = useContentProvider();
  return (
    <Tooltip title={left.isExpanded ? "Reset" : "Expand Left"}>
      {React.cloneElement(child, {
        type: child?.props?.type || "text",
        icon:
          child?.props?.icon || left.isExpanded ? (
            <PicLeftOutlined />
          ) : (
            <PicCenterOutlined />
          ),
        onClick: callAll(() =>
          dispatch({
            type: left.isExpanded ? "RESET" : "EXPAND_LEFT",
          })
        ),
      })}
    </Tooltip>
  );
}

function RightPanelSizeButton({ children: child }) {
  const [{ right }, dispatch] = useContentProvider();
  return (
    <Tooltip title={right.isExpanded ? "Reset" : "Expand Right"}>
      {React.cloneElement(child, {
        type: child?.props?.type || "text",
        icon:
          child?.props?.icon || right.isExpanded ? (
            <PicRightOutlined />
          ) : (
            <PicCenterOutlined />
          ),
        onClick: callAll(() =>
          dispatch({
            type: right.isExpanded ? "RESET" : "EXPAND_RIGHT",
          })
        ),
      })}
    </Tooltip>
  );
}

const usePageContext = () => React.useContext(PageContext);

export {
  Page,
  PageContent,
  PageContentBase,
  PageHeader,
  RightPanel,
  RightPanelSizeButton,
  LeftPanel,
  LeftPanelSizeButton,
  usePageContext,
};
