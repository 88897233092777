import React from "react";
import { Button, Card, message, Tooltip } from "antd";
import { CopyFilled, RollbackOutlined } from "@ant-design/icons";
import useClipboard from "use-clipboard-hook";

export default function SimpleJobOutput({ output, canReset, reset }: { output: string, canReset?: boolean, reset?: () => void }) {
    const { copy } = useClipboard({
        onSuccess: () => message.success(`Copied.`),
    });

    const onCopy = () => {
        copy(output);
    }

    return (
        <Card title="" extra={
            <>
                <Tooltip title="Copy">
                    <Button
                        type="text"
                        icon={<CopyFilled />}
                        onClick={() => onCopy()}
                    />
                </Tooltip>
                {canReset && <Tooltip title="Go Back">
                    <Button onClick={reset} icon={<RollbackOutlined />} type="text"></Button>
                </Tooltip>}
            </>
        }>

            <pre style={{ maxHeight: '25ch' }} >{output}</pre>
        </ Card>

    );
}
