import React from "react";
import { Notification } from "types";
import { Button, ButtonProps } from "antd";
import RoleGuard from "../standalone/role-guard";
import queryClient, { useMutation } from "components/utils/queryClient";

type DeleteNotificationButtonProps = {
  resource: Notification;
} & Omit<ButtonProps, "resource">;

export default function DeleteNotificationButton({
  resource,
  ...props
}: DeleteNotificationButtonProps) {
  const { mutateAsync } = useMutation();

  return (
    <RoleGuard requiredRoles={["Administrator"]}>
      <Button
        {...props}
        onClick={() =>
          mutateAsync(
            {
              key: `/notification/${resource?.id}`,
              action: "delete",
            },
            {
              onSuccess: () => {
                queryClient.refetchQueries("/notification");
                queryClient.refetchQueries("/notification/last");
              },
            }
          )
        }
      />
    </RoleGuard>
  );
}
