import { presetPrimaryColors } from "@ant-design/colors";
import { Space, Typography } from "antd";
import React from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";

export default function Logo() {
  const {currentTheme} = useThemeSwitcher()
  return (
    <Space>
      <div style={{ width: 28, margin: "0 18px", flex: "0" }}>
        <img
          alt="powershell universal logo"
          style={{ width: "inherit", height: "inherit" }}
          src="logo.png"
        />
      </div>
      <Space direction="horizontal" size={0}>
        <Typography.Text
          style={{
            fontFamily: "SFProDisplay-Black",
            fontSize: 14,
            color: presetPrimaryColors["blue"]
          }}
        >
          PowerShell
        </Typography.Text>
        <Typography.Text
          style={{
            fontFamily: "SFProDisplay-Semibold",
            fontSize: 14,
            color: currentTheme === 'dark' ? '#fff' : '#1f1f1f'
          }}
        >
          Universal
        </Typography.Text>
      </Space>
    </Space>
  );
}
