import { Form, Select } from "antd";
import React from "react";
import * as Icons from '@ant-design/icons';

const ignoredKeys = ["IconProvider", "setTwoToneColor", "getTwoToneColor", "createFromIconfontCN", "default"]

export default function PSUIcon (props) {

    return (
        <Form.Item
            name={props.name}
            label={props.label || props.displayName}
            tooltip={props.description}
        >
            
            <Select>
                {Object.keys(Icons).filter(key => ignoredKeys.find(ignoredKey => ignoredKey === key) == null).map(key => <Select.Option key={key} value={key}>{React.createElement(Icons[key])} {key}</Select.Option>)}
            </Select>
        </Form.Item>
    )
}