import React, { lazy } from "react";
import { Button, Result } from "antd";
import { useNavigate  } from "react-router-dom";
const UnAuthorizedImage = lazy(
  () =>
    import(
      /* webpackChunkName: 'UnauthorizedImage' */ "./unauthorized-image.js"
    )
);

export default function UnAuthorizedPage() {
  const navigate = useNavigate();
  return (
    <Result
      title="Unauthorized Access"
      subTitle="You are not authorized to view this page."
      icon={<UnAuthorizedImage />}
      extra={[
        <Button type="primary" onClick={() => navigate("/admin")}>
          Home
        </Button>,
        <Button type="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      ]
      }
    />
  );
}
