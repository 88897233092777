import React from "react";
import { JobStatus } from "types";
import { Progress, Space, Typography } from 'antd';
import { HistoryOutlined } from "@ant-design/icons";

export default function JobProgress({ job }) {

    if (job?.status !== JobStatus.Running || !job?.activity) return <></>;

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            {job?.currentOperation && <Typography.Text type="secondary" style={{ fontSize: 12 }}>{job?.currentOperation}</Typography.Text>}
            {job?.activity && <Typography.Text type="secondary" style={{ fontSize: 12 }}>{job?.activity}</Typography.Text>}
            <Progress percent={job?.percentComplete} />
            {job?.secondsRemaining !== -1 && (
                <Space>
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                        <HistoryOutlined style={{ color: "inherit", fontSize: "inherit" }} />
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                        {job?.secondsRemaining} seconds remaining
                    </Typography.Text>
                </Space>
            )}

        </Space>

    )
}