import { CloseCircleOutlined, ControlOutlined, EditOutlined, LogoutOutlined, SaveOutlined, SettingOutlined, ToolOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, message, PageHeader, Popconfirm, Space, Spin } from "antd";
import { useEffect, useState } from "react";
import PSUComponent from "./Component";
import { DesignSurface } from './DesignSurface';
import { Page as PSUPage, Variable } from 'types';
import Scrollbars from "rc-scrollbars";
import { useMutation } from "components/utils/queryClient";
import useNormalizeData from "components/utils/normalizedRecord";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { SiderTheme } from "antd/es/layout/Sider";
import { useNavigate, useParams } from "react-router";
import PageProperties from './PageProperties';
import * as Icons from '@ant-design/icons';
import { WidthProvider, Responsive } from "react-grid-layout";
import ThemeToggle from "components/ui/theme/themeToggle";
import Logo from "components/ui/Logo";
import React from "react";
import RoleGuard from "components/standalone/role-guard";
import { Page as PageContext } from "components/pages/Page";
import { VariableContext, PageVariable } from './VariableContext';
import VariableDrawer from './VariableDrawer';
import { useQuery } from "react-query3";
import { loadDataSource } from "./DataSource";
import { ComponentPropertyType, IComponentProperty } from "./ComponentProperty";

require('react-grid-layout/css/styles.css');
require('react-resizable/css/styles.css');

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const { Header, Sider, Content } = Layout;


export interface PageProps {
  page: PSUPage,
  pages: Array<PSUPage>,
  formsAuth: boolean,
  logout: () => void,
  username?: string
  licensed: boolean;
}



export default function Page(props: PageProps) {
  const { page: propPage, pages, licensed } = props;

  const builtInVariables = [
    {
      name: '$username',
      value: props.username,
      session: false,
      context: 'global'
    },
    {
      name: '$pagename',
      value: props.page.name,
      session: false,
      context: 'global'
    }
  ]

  const replaceVariables = (context, props, properties: Array<IComponentProperty>) => {
    if (!licensed) return props;
    const newProps = { ...props };

    properties.forEach(property => {
      context.variables.forEach(variable => {
        switch (property.type) {
          case ComponentPropertyType.String:
            newProps[property.name] = newProps[property.name]?.replace(variable.name, variable.value);
            break;
          case ComponentPropertyType.DataSource:
            newProps['dataSource'] = newProps['dataSource']?.replace(variable.name, variable.value);
            break;
        }
      });
    });

    return newProps;
  }

  const navigate = useNavigate();
  const { currentTheme } = useThemeSwitcher()
  const [editing, setEditing] = useState(false);
  const [toolboxVisible, setToolboxVisible] = useState(false);
  const [variablesVisible, setVariablesVisible] = useState(false);
  const [pagePropertiesVisible, setPagePropertiesVisible] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const { mutateAsync } = useMutation();
  const normalizedRecord = useNormalizeData();

  const [page, setPage] = useState(propPage);
  const [components, setComponents] = useState(propPage.components);
  const [layout, setLayout] = useState(propPage.layout || { lg: [] });

  const params = useParams();
  const paramVariables = Object.keys(params).map(key => ({
    name: `$${key}`,
    value: params[key],
    session: false,
    context: 'route'
  }));

  const [variables, setVariables] = useState<Array<PageVariable>>([
    ...builtInVariables,
    ...paramVariables
  ]);
  const [credentials, setCredentials] = useState<Array<Variable>>([]);
  const { isLoading: variablesLoading } = useQuery<Array<Variable>>('/variable', {
    enabled: !!props.username,
    onSuccess: (data) => {
      setCredentials(data.filter(m => m.type === 'PSCredential'));
      setVariables(
        [
          ...variables,
          ...data.filter(m => !m.vault).map(v => ({
            name: '$' + v.name,
            value: v.value,
            context: 'platform',
            session: false
          }))
        ]
      );
    }
  });

  // This is horrible and I don't know why I can't use react-query here. 
  // I also don't know why I can't specify dependencies in the query.
  useEffect(() => {
    loadDataSource(propPage.dataSource, propPage.dataSourceType).then(data => {
      if (data === null) return;

      setVariables(
        [
          ...variables,
          ...Object.keys(data[0]).map(v => ({
            name: '$' + v,
            value: data[0][v],
            context: 'pageDataSource',
            session: false
          }))
        ]

      );
    })
    return () => { }
    // eslint-disable-next-line
  }, []);

  const setVariable = (variable: PageVariable) => {
    variable.name = variable.name.startsWith('$') ? variable.name : "$" + variable.name;
    var newVariables = variables.slice().filter(m => m.name !== variable.name);
    newVariables.push(variable);
    setVariables(newVariables);
  }

  if (variablesLoading) { // || isLoading) {
    return <Spin />
  }

  const onCancel = () => {
    setEditing(false);
    setPage(propPage);
    setComponents(propPage.components);
    setLayout(propPage.layout || { lg: [] });
  }

  const onSave = async () => {
    await mutateAsync({
      key: `/page/${page.id}`,
      action: "update",
      ...normalizedRecord({ ...page, components, layout })
    });
    window.location.reload();
    setEditing(false);
  }

  const PageLayout = ({ page }) => {
    return (
      <PageContext resource={{}}>
        <PageHeader
          title={page.name}
          subTitle={page.description}>
          {editing ?
            <DesignSurface
              components={components}
              setComponents={setComponents}
              layout={layout}
              setLayout={setLayout}
              toolboxVisible={toolboxVisible}
              setToolboxVisible={setToolboxVisible} /> :
            <ResponsiveReactGridLayout
              layouts={layout}
              isDraggable={false}
              isResizable={false}
              measureBeforeMount={true}
              useCSSTransforms={false}
              rowHeight={10}
              cols={{ lg: 50, md: 10, sm: 6, xs: 4, xxs: 2 }}>
              {page.components.map(component =>
                <div key={component.id} id={component.id}>
                  <PSUComponent {...component} />
                </div>)
              }
            </ResponsiveReactGridLayout>
          }
        </PageHeader>
      </PageContext>
    )
  }

  let pageButtons;
  if (editing) {
    pageButtons = <>
      <Button onClick={onSave} icon={<SaveOutlined />}>Save</Button>
      <Popconfirm title="Are you sure?" onConfirm={onCancel}><Button icon={<CloseCircleOutlined />} >Cancel</Button></Popconfirm>
      <Button onClick={() => setPagePropertiesVisible(true)} icon={<SettingOutlined />} >Properties</Button>
      <Button onClick={() => setToolboxVisible(true)} icon={<ToolOutlined />}>Toolbox</Button>
      <Button onClick={() => setVariablesVisible(true)} icon={<ControlOutlined />}>Variables</Button>
    </>
  } else {
    pageButtons = <>
      <RoleGuard requiredRoles={["Administrator"]}>
        <Button onClick={() => setEditing(true)} icon={<EditOutlined />}>Edit</Button>
      </RoleGuard>
      {!page.hideHeader && (props.formsAuth ? (
        <Dropdown
          placement="bottomCenter"
          //@ts-ignore
          destroyPopupOnHide={true}
          trigger={["click"]}
          overlay={() => (
            <Menu onClick={() => props.logout()}>
              <Menu.Item key="signout" icon={<LogoutOutlined />}>
                Signout
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="text" icon={<UserOutlined />}>{props.username}</Button>
        </Dropdown>
      ) : (
        <Space>
          <UserOutlined />
          {props.username}
        </Space>
      ))}
    </>
  }

  function onClick({ key }) {
    if (editing) {
      message.warning("Can't navigate while editing");
      return;
    }

    navigate(key);
  }

  return (
    <VariableContext.Provider value={{
      credentials,
      variables,
      setVariable,
      replaceVariables
    }}>
      <Layout style={{ height: "100vh" }}>
        {!page.hideHeader && <Header style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "#ffffff",
          backgroundColor: currentTheme === 'light' ? '#fff' : '#1f1f1f',
          padding: "0px 8px"
        }}>
          <Logo />
          <div style={{ width: 28, margin: "0 18px", flex: "0" }}></div>
          <Space>
            <ThemeToggle
              checkedChildren="Dark"
              unCheckedChildren="Light"
              size="small"
            />
            {pageButtons}
          </Space>
        </Header>}
        <Layout hasSider={page.showNavigation}>
          {page.hideHeader && <Space style={{ marginTop: '10px', marginRight: '10px', marginLeft: 'auto' }}>{pageButtons}</Space>}
          {page.showNavigation ? (
            <Sider collapsible collapsed={sideCollapsed} onCollapse={setSideCollapsed} theme={currentTheme as SiderTheme}>
              <Menu theme={currentTheme as SiderTheme} defaultSelectedKeys={['nav' + page.id]} onClick={onClick} >
                {pages.filter(m => m.showInNavigation).map(navPage => <Menu.Item key={"/" + navPage.name} icon={navPage.icon && React.createElement(Icons[navPage.icon])}> {navPage.name}</Menu.Item>)}
              </Menu>
            </Sider>) : <></>}
          <Content>
            <Scrollbars hideTracksWhenNotNeeded autoHide disableDefaultStyles>
              <PageLayout page={page} />
              <PageProperties visible={pagePropertiesVisible} setVisible={setPagePropertiesVisible} page={page} setPage={setPage} />
            </Scrollbars>
          </Content>
          <VariableDrawer visible={variablesVisible} setVisible={setVariablesVisible} page={page} licensed={licensed} />
        </Layout>
      </Layout>
    </VariableContext.Provider>
  )
}