import { Form, InputNumber } from "antd";
import React from "react";

export default function Number (props) {
    return (
        <Form.Item
            label={props.displayName}
            name={props.name}
            rules={[{ required: props.required }]}
            tooltip={props.description}
        >
            <InputNumber min={0} max={100} />
        </Form.Item>
    )
}