import React from "react";
import { Typography } from 'antd';
import { Route, Routes } from "react-router-dom";
import { Page as PSUPage } from 'types';
import Page from './Page'
import { useQuery } from "react-query3";
import queryClient from "components/utils/queryClient";
import useAppStore from "../context/app/Hooks";


export default function PageDashboard() {
    const { data: pages, isLoading } = useQuery<PSUPage[]>("/page");
    const { data: authType } = useQuery<string>("/authmethod");
    const { refetch: logoff } = useQuery<string>("/signout", {
        enabled: false,
        onSuccess: () => queryClient.clear(),
    });
    const { userData, licensedFeatures } = useAppStore();

    const onLogout = () => {
        logoff().then(() => (window.location.href = "/login"))
    };

    if (isLoading || !pages) {
        return <Typography>Loading...</Typography>
    }

    const pageRoutes = pages.map(page => {
        return <Route key={"route" + page.name} path={page.url && page.url !== '' ? page.url : page.name} element={
            <Page
                page={page}
                pages={pages}
                formsAuth={authType === "Cookies"}
                logout={onLogout}
                username={userData?.username}
                licensed={licensedFeatures?.length > 0} />
        } />
    })

    return <Routes>{pageRoutes}</Routes>
}