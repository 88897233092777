import React from "react";
import { Form, Input, Select, Typography } from "antd";

export default function Variable(props) {

    return (
        <>
            <Typography.Text strong>Variable</Typography.Text>
            <Typography.Paragraph>{props.description}</Typography.Paragraph>

            <Form.Item
                label="Type"
                name={["variable", "type"]}
                rules={[{ required: true }]}
            >
                <Select>
                    <Select.Option key="temporary" value="temporary">Temporary</Select.Option>
                    <Select.Option key="session" value="session">Session</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Name"
                name={["variable", "name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>

        </>
    );
}
