import React from "react";
import { Result } from "antd";
import NotFoundImage from "./notfound-image"
export default function NotFoundPage() {
  return (
    <Result
      title="Maintenance Mode"
      subTitle="The page you looking for is currently undergoing maintenance."
      icon={<NotFoundImage />}
    />
  );
}
