import { Form, Checkbox } from "antd";
import React from "react";

export default function PSUCheckbox (props) {
    return (
        <Form.Item
            name={props.name}
            rules={[{ required: props.required }]}
            valuePropName="checked" 
            label={props.displayName}
            tooltip={props.description}
        >
            <Checkbox></Checkbox>
        </Form.Item>
    )
}