export interface IComponentProperty {
    name: string;
    displayName: string;
    description: string | React.ReactNode;
    required: boolean;
    type: ComponentPropertyType;
    options?: Array<ISelectOption>;
    category?: string;
}

export interface ISelectOption {
    value: string | number;
    text: string;
}

export enum ComponentPropertyType {
    String = 0,
    DataSource = 1,
    Number = 2,
    Boolean = 3,
    Target = 4,
    FormTarget = 5,
    Select = 6,
    Icon = 7,
    TableColumns = 8,
    FormFields = 9,
    TargetParameters = 10,
    Variable = 11
}