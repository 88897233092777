import React from "react";
import { Table as AntTable, Space, Input } from "antd";
import { CustomColumns, PSUTypes } from "../../types";
import { TableProps } from "antd/lib/table";
import { Sorter, stringSorter } from "components/utils/sorter";
import { matchSorter } from "match-sorter";
import { QueryObserverResult } from "react-query3";
import Loader from "components/utils/Loader";
import TableTitle from "./TableTitle";
import MarketplaceSearchBox from "./MarketplaceSearchBox";


interface CustomTableProps<T> extends Omit<TableProps<T>, "title"> {
  columns: CustomColumns<T>[];
  data: T[];
  title?: string;
  queryFn?: () => Promise<QueryObserverResult<T[], unknown>>;
  actions?: React.ReactNode[];
  showSearch?: boolean;
  showMarketplaceSearch?: boolean;
  onSearch?: (value: any, event: any) => Promise<void>;
  expandedRowRender?: any;
  rowExpandable?: any;
}

const Table: React.FC<CustomTableProps<PSUTypes>> = ({
  columns,
  data,
  title,
  actions,
  queryFn,
  showSearch = true,
  showMarketplaceSearch = false,
  onSearch,
  expandedRowRender,
  rowExpandable,
  ...restOfProps
}) => {
  const [value, setValue] = React.useState("");
  const [page, setPage] = React.useState(1);

  const keysToSort = React.useMemo(
    () =>
      columns.map(
        (col) => (col?.dataIndex && `${col?.dataIndex}`) || `${col?.key}`
      ),
    [columns]
  );

  function query(search: any) {
    let results = matchSorter(data, search, {
      keys: keysToSort,
    });
    return results;
  }

  React.useEffect(() => {
    queryFn && queryFn();
  }, [queryFn]);

  const sortableColumns = columns.map((column) => {
    const { sorter, dataIndex, key, ...otherColumnProps } = column;

    column["sortable"] = dataIndex === "actions" ? false : true;

    if (column.sortable) {
      return {
        ...otherColumnProps,
        dataIndex,
        ellipsis: true,
        sorter: {
          compare: (rowA, rowB) => {
            if (
              typeof rowA[(dataIndex && dataIndex.toString()) || key] ===
              "number"
            ) {
              return Sorter.DEFAULT(
                rowA[dataIndex.toString() || key],
                rowB[dataIndex.toString() || key]
              );
            } else {
              return stringSorter(
                rowA[(dataIndex && dataIndex.toString()) || key],
                rowB[(dataIndex && dataIndex.toString()) || key]
              );
            }
          },
        },
      };
    }

    return column;
  });

  return (
    <Loader>
      <AntTable
        expandable={{
          expandedRowRender: expandedRowRender,
          rowExpandable: rowExpandable,
        }}
        title={() => {
          return (
            <TableTitle
              style={{
                width: "100%",
                justifyContent: !title ? "flex-end" : "space-between",
              }}
            >
              {title}
              <Space>
                {showSearch && (
                  <Input.Search
                    allowClear
                    placeholder={`Search ${data?.length} items...`}
                    bordered={false}
                    onSearch={(value) => setValue(value)}
                    enterButton
                  />
                )}
                {showMarketplaceSearch && <MarketplaceSearchBox page={page} />}
                {actions}
              </Space>
            </TableTitle>
          );
        }}
        columns={sortableColumns}
        dataSource={value ? query(value) : data}
        bordered={false}
        scroll={
          {
            x: true
          }
        }
        pagination={{
          hideOnSinglePage: true,
          onChange: (page) => setPage(page),
        }}
        {...restOfProps}
      />
    </Loader>
  );
};

export default Table;
