import React, { useState } from "react";
import { useInterval } from "ahooks";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Job, JobStatus } from "../../types";
import { Typography } from "antd";


dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

export default function JobRunTime({ job }: { job: Job }) {

  const [second, setSecond] = useState(0);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);



  const start = dayjs(job.startTime);
  const refreshSeconds = () => {
    const end = dayjs();
    let duration = dayjs.duration(end.diff(start));
    let hr = duration.hours();
    let min = duration.minutes();
    let sec = duration.seconds();

    setHour(hr);
    setMinute(min);
    setSecond(sec);
  };

  useInterval(() => refreshSeconds(), job.status === JobStatus.Running || job.status === JobStatus.WaitingOnFeedback ? 1000 : null, {
    immediate: true
  });

  return (
    <Typography.Text type="secondary" style={{ fontSize: 12 }}>
      {`${hour !== 0 ? `${hour}h` : ""} ${minute !== 0 ? `${minute}m` : ""} ${second !== 0 ? `${second}s` : "-"
        }`}
    </Typography.Text>
  );
}
