import { Form, Select } from "antd";
import React from "react";

export default function PSUSelect (props) {
    return (
        <Form.Item
            label={props.displayName}
            name={props.name}
            rules={[{ required: props.required }]}
            tooltip={props.description}
        >
            <Select>
                {props.options && props.options.map(option => {
                    return <Select.Option value={option.value}>{option.text}</Select.Option>
                })}
            </Select>
        </Form.Item>
    )
}