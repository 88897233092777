import queryClient from "components/utils/queryClient";
import { Endpoint } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const endpointSchema: CustomSchema<Endpoint>[] = [
  {
    name: "url",
    label: "URL",
    hasFeedback: true,
    dependencies: ["method"],
    component: "input",
    tooltip:
      "Enter the URL of this endpoint. You can start segments of the URL with a colon (e.g. '/user/:id') to denote variables.",
    required: true,
    uniqe: true,
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
    uniqe: false,
  },
  {
    name: "method",
    label: "Method",
    component: "methodSelector",
    required: false,
    uniqe: false,
  },
  {
    name: "authentication",
    label: "Authentication",
    component: "authSwitch",
    valuePropName: 'checked',
    required: false,
    uniqe: false,
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.authentication !== curValues.authentication || curValues.authentication === true
    },
    children: ({ getFieldValue }) => {
      const method = getFieldValue("method")
      const url = getFieldValue("url")
      let defaultRoles = queryClient.getQueryData<Endpoint[]>(`/endpoint`)?.find(endpoint => endpoint?.method === method && endpoint?.url === url)?.role
      return getFieldValue("authentication") === true ? (
        <Field
          component="roleTags"
          name="role"
          label="Role"
          preserve={false}
          required={true}
          initialValue={defaultRoles || null}
          dependencies={['authentication']}
          uniqe={false}
        />
      ) : null;
    },
  },
  {
    component: "errorAction",
    name: "errorAction",
    label: "Error Action",
    initialValue: "SilentlyContinue",
    tooltip: "The error action to take when the endpoint throws an error. If set to Stop, the script will return a 500 error when a terminating error is thrown.",
  },
  {
    name: "tag",
    label: "Tags",
    component: "tag",
    initialValue: []
  },
];
