import { Alert } from "antd";
import { IComponentProperty } from "./ComponentProperty";
import PSUText, { PSUTextProps, NewPSUText } from "./components/Text";
import PSUForm, { PSUFormProps, NewPSUForm } from "./components/Form";
import PSUTable, { PSUTableProps, NewPSUTable } from "./components/Table";
import PSUStatistic, { PSUStatisticProps, NewPSUStatistic } from "./components/Statistic";
import PSUButton, { PSUButtonProps, NewPSUButton } from "./components/Button";
import PSUAlert, { PSUAlertProps, NewPSUAlert } from "./components/Alert";
import * as PSUTitle from './components/Title';
import * as PSUParagraph from './components/Paragraph';
import * as PSUImage from './components/Image';
import * as Tag from './components/Tag';
import * as LineChart from './components/LineChart';
import * as LiquidChart from './components/LiquidChart';
import * as BarChart from './components/BarChart';
import * as PSUSwitch from './components/Switch';
import * as PieChart from './components/PieChart';
import { VariableContext } from './VariableContext';

export default function PSUComponent(props) {
    return (
        <Alert.ErrorBoundary>
            <VariableContext.Consumer>
                {(context) => {
                    return RenderComponent(context.replaceVariables(context, props, GetProps(props)));
                }}
            </VariableContext.Consumer>
        </Alert.ErrorBoundary>
    )
}

export function RenderComponent(props) {
    switch (props.type) {
        case "Alert":
            return <PSUAlert {...props} />;
        case "Bar":
            return <BarChart.Component {...props} />;
        case "Button":
            return <PSUButton {...props} />;
        case "Line":
            return <LineChart.Component {...props} />;
        case "Liquid":
            return <LiquidChart.Component {...props} />;
        case "Image":
            return <PSUImage.Component {...props} />;
        case "Text":
            return <PSUText {...props} />;
        case "Form":
            return <PSUForm {...props} />;
        case "Paragraph":
            return <PSUParagraph.Component {...props} />;
        case "Pie":
            return <PieChart.Component {...props} />;
        case "Statistic":
            return <PSUStatistic {...props} />;
        case "Switch":
            return <PSUSwitch.Component {...props} />;
        case "Table":
            return <PSUTable {...props} />;
        case "Tag":
            return <Tag.Component {...props} />;
        case "Title":
            return <PSUTitle.Component {...props} />;
        default:
            return <Alert message={`Unknown component type: ${props.type}`} type="error" />
    }
}

export function GetProps(props): Array<IComponentProperty> {
    switch (props.type) {
        case "Alert":
            return PSUAlertProps;
        case "Bar":
            return BarChart.Props;
        case "Button":
            return PSUButtonProps;
        case "Line":
            return LineChart.Props;
        case "Liquid":
            return LiquidChart.Props;
        case "Statistic":
            return PSUStatisticProps;
        case "Text":
            return PSUTextProps;
        case "Form":
            return PSUFormProps;
        case "Image":
            return PSUImage.Props;
        case "Pie":
            return PieChart.Props;
        case "Paragraph":
            return PSUParagraph.Props;
        case "Switch":
            return PSUSwitch.Props;
        case "Tag":
            return Tag.Props;
        case "Table":
            return PSUTableProps;
        case "Title":
            return PSUTitle.Props;
    }
}

export function NewComponent(type: string) {
    switch (type) {
        case "Alert":
            return NewPSUAlert();
        case "Bar":
            return BarChart.Constructor();
        case "Button":
            return NewPSUButton();
        case "Line":
            return LineChart.Constructor();
        case "Liquid":
            return LiquidChart.Constructor();
        case "Image":
            return PSUImage.Constructor();
        case "Text":
            return NewPSUText();
        case "Form":
            return NewPSUForm();
        case "Paragraph":
            return PSUParagraph.Constructor();
        case "Pie":
            return PieChart.Constructor();
        case "Statistic":
            return NewPSUStatistic();
        case "Switch":
            return PSUSwitch.Constructor();
        case "Table":
            return NewPSUTable();
        case "Tag":
            return Tag.Constructor();
        case "Title":
            return PSUTitle.Constructor();
    }
}