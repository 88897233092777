import React from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Layout,
  Space,
  Alert,
  Card,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { presetDarkPalettes } from "@ant-design/colors";
import { useQuery, useMutation } from "react-query3";
import { LoginPage as LoginPageType } from "./../../types";
import CustomLogin from "./customlogin";
import { Skeleton } from 'antd';
import axios from "axios";

// login to admin console
export function useLogin() {
  return useMutation(
    async (credentials: { username: string; password: any }) => {
      let res = await axios.post(
        `/api/v1/signin${window.location.search}`,
        JSON.stringify(credentials),
        {
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      return res.data;
    }
    // {
    //   onError: (error: AxiosError, creds) => {
    //     // @ts-ignore
    //     return error.response.data?.errorMessage;
    //   },
    //   onSuccess: (data, creds) => {
    //     //@ts-ignore
    //     window.location.href = data?.returnUrl;
    //   },
    // }
  );
}

export default function LoginPage() {
  const { data, isLoading: isLoginPageLoading } = useQuery<LoginPageType>("/loginPage");
  const { mutateAsync, isLoading, data: userInfo } = useLogin();

  async function login(values: { username: string; password: any }) {
    await mutateAsync({
      username: values.username,
      password: values.password,
    }, {
      onSuccess: (data, variables) => {
        if (data?.returnUrl) {
          window.location.href = data?.returnUrl
        }
      }
    });
  }

  if (isLoginPageLoading) {
    return <Skeleton />;
  }

  return !data.defaultLoginPage ? (
    <CustomLogin />
  ) : (
    <Layout
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Space size={24} direction="vertical" align="center">
        <div style={{ width: 128, flex: "0" }}>
          <img
            alt="powershell universal logo"
            style={{ width: "inherit", height: "inherit" }}
            src="logo.png"
          />
        </div>
        <Typography>
          <Space direction="horizontal" size={0}>
            <Typography.Text
              style={{
                color: presetDarkPalettes["blue"][6],
                fontFamily: "SFProDisplay-Black",
                fontSize: 24,
              }}
            >
              PowerShell
            </Typography.Text>
            <Typography.Text
              style={{
                fontFamily: "SFProDisplay-Semibold",
                fontSize: 24,
              }}
            >
              Universal
            </Typography.Text>
          </Space>
        </Typography>
        <Typography>
          <Typography.Paragraph
            style={{
              textAlign: "center",
              fontFamily: "SFProDisplay-Semibold",
              maxWidth: 340,
            }}
          >
            PowerShell Universal allows you to run jobs, schedule scripts, build
            web pages and APIs, and control access; all under a single pane of
            beautiful glass.
          </Typography.Paragraph>
        </Typography>

        <Card
          bordered={false}
          style={{
            maxWidth: 330,
            maxHeight: 360,
            padding: 24,
            flex: "unset",
            // backgroundColor: currentTheme === "dark" ? "#141414" : ,
          }}
        >
          <Space size={24} direction="vertical">
            <Typography style={{ width: "100%" }}>
              <Typography.Title
                level={5}
                style={{
                  width: "inherit",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // color: "#DBDBDB",
                }}
              >
                Log in to your account
              </Typography.Title>
            </Typography>

            <Form
              name="normal_login"
              style={{ maxWidth: 330, maxHeight: 360 }}
              onFinish={(values) => login(values)}
            >
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your Username!" },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{ color: presetDarkPalettes["blue"][6] }}
                    />
                  }
                  bordered={false}
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
              >
                <Input
                  prefix={
                    <LockOutlined
                      style={{ color: presetDarkPalettes["blue"][6] }}
                    />
                  }
                  bordered={false}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  block
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            {userInfo?.errorMessage && (
              <Form.Item>
                <Alert message={
                  //@ts-ignore
                  userInfo?.errorMessage} type="error" showIcon />
              </Form.Item>
            )}
          </Space>
        </Card>
        {data.defaultAuthentication && <Alert type="warning" message="Default Authentication" description="You have not configured authentication or authorization. The default user name is 'Admin' and you can use any password."></Alert>}

        <Typography>
          <Typography.Paragraph>Ironman Software © 2021</Typography.Paragraph>
        </Typography>
      </Space>
    </Layout>
  );
}
