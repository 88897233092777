import React, { Fragment } from "react";
import { Button } from "antd";
import { Field, Form, FormBase } from "components/ui/Form/Form";
import {
  useModalContext,
  ModalOpenButton,
  ModalContent,
} from "components/ui/Modal";
import { AccessControlTypes, Job, JobFeedback, Script } from "types";
import { useQuery } from "react-query3";
import { QuestionCircleFilled } from "@ant-design/icons";

export default function FeedbackModal({ job, feedbackComplete }: { job: Job, feedbackComplete: () => void }) {
  const { onOk, form } = useModalContext();

  const { data } = useQuery<JobFeedback>(`/job/${job?.id}/feedback/incomplete`);
  const { data: script } = useQuery<Script>(`/script/path/${job?.scriptFullPath}`);

  const { data: feedback } = useQuery<JobFeedback>(`/jobfeedback/${data?.id}`, {
    enabled: !!data?.id,
  });

  function handleOk() {
    onOk("/jobfeedback", "update", {
      ...feedback,
      resourceInfo: {
        schemaName: "jobfeedback",
        self: `/jobfeedback/${feedback?.id}`,
        parent: "/jobfeedback",
      },
    } as JobFeedback).then(() => feedbackComplete());
  }

  return (
    <Fragment>
      <ModalOpenButton requiredRoles={["Administrator", "Operator", "Execute"]} requiredAccessControls={AccessControlTypes.Execute} accessControls={script?.accessControls}>
        <Button
          children="Respond to feedback"
          type="primary"
          icon={<QuestionCircleFilled />}
        />
      </ModalOpenButton>
      <ModalContent title="Feedback" onClickOk={handleOk}>
        <Form schemaName="jobfeedback">
          <FormBase name="set_job_feedback_form" form={form} preserve={false}>
            <Field component="input" label={feedback?.message} name="data" />
          </FormBase>
        </Form>
      </ModalContent>
    </Fragment>
  );
}
