import { Tag } from "types";
import { CustomSchema } from "./types";

export const tagSchame: CustomSchema<Tag>[] = [
  {
    component: "preview",
    name: "preview",
    wrapperCol: {
      span: 24,
    },
    labelCol: {
      span: 0,
    },
    required: false,
  },
  {
    component: "input",
    label: "Name",
    name: "name",
    required: true,
  },
  {
    component: "input",
    label: "Description",
    name: "description",
    required: false,
  },
  {
    component: "colorPicker",
    name: "color",
    wrapperCol: {
      span: 24,
    },
    labelCol: {
      span: 0,
    },
    required: false,
  },
];
