import { Input } from "antd";
import axios, { AxiosError } from "axios";
import queryClient from "components/utils/queryClient";
import { useMutation, useQuery } from "react-query3";
import React from "react";
import { DashboardMarketplaceBrowsePage } from "types";

async function searchInMarketplace({ page, text }) {
  var data = new FormData();
  data.append("searchText", text);
  return (
    await axios.post<DashboardMarketplaceBrowsePage>(
      `/api/v1/dashboard/marketplace/module?page=${page}`,
      data
    )
  ).data;
}

export default function MarketplaceSearchBox({
  page,
}: {
  page: number;
}): JSX.Element {
  const { mutateAsync, isLoading } = useMutation<
    DashboardMarketplaceBrowsePage,
    AxiosError,
    { text: string }
  >(
    ({ text }): Promise<DashboardMarketplaceBrowsePage> =>
      searchInMarketplace({ page, text }),
    {
      onMutate: (text) => {
        queryClient.getQueryData(
          `/dashboard/marketplace/module?page=${page}`
        );
        if (!text) return;
      },
      onSuccess: (data, variables) => {
        if (variables?.text) {
          queryClient.setQueryData(
            `/dashboard/marketplace/module?page=${page}&searchText=${variables?.text}`,
            data
          );
          queryClient.setQueryData(
            `/dashboard/marketplace/module?page=${page}`,
            data
          );
        }
      },
    }
  );

  const {
    refetch,
  } = useQuery<DashboardMarketplaceBrowsePage>(
    `/dashboard/marketplace/module?page=${page}`,
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
    }
  );

  async function onSearch(
    value: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) {
    value ? await mutateAsync({ text: value }) : refetch()
  }

  return (
    <Input.Search
      placeholder="Search in marketPlace"
      onSearch={(value, event) => {
        onSearch(value);
      }}
      crossOrigin="true"
      loading={isLoading}
      enterButton
      allowClear
      bordered={false}
    />
  );
}
