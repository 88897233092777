import React from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import {
  Job,
  JobFeedback,
  JobLog,
  JobPipelineOutput,
  JobQuery,
  JobStreamOutput,
  Script,
} from "types";
import queryClient, { useMutation } from "./utils/queryClient";

export default function useConnection() {
  const { mutateAsync } = useMutation();

  function updateJobQueryCache(data) {
    queryClient.setQueryData("/job", (old: JobQuery) => {
      const jobIndex = old?.page?.findIndex((j) => j.id === data.id);
      if (jobIndex) {
        old.page[jobIndex] = data;
      }
      return old;
    });
    queryClient.refetchQueries("/job");
  }

  function updateScriptJobQueryCache(data) {
    queryClient.setQueryData(
      `/script/${data?.scriptFullPath}/job`,
      (old: JobQuery) => {
        const jobIndex = old?.page?.findIndex((j) => j.id === data.id);
        if (jobIndex) {
          old.page[jobIndex] = data;
        }
        return old;
      }
    );
    queryClient.refetchQueries(`/script/${data?.scriptFullPath}/job`);
  }

  React.useEffect(() => {
    const connection = new HubConnectionBuilder()
      .withUrl(`/notificationhub`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Debug)
      .build();

    connection.on("licenseChanged", () => {
      queryClient.refetchQueries("/license");
    });

    connection.on("statsChanged", () => {
      // queryClient.invalidateQueries('/stats');
    });

    connection.on("jobStarted", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}/time`, 0);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
    });

    connection.on("jobCanceled", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
    });

    connection.on("jobFailed", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
      queryClient.refetchQueries("/job");
    });

    connection.on("jobCompleted", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
    });

    connection.on("jobRunning", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
    });

    connection.on("jobProgress", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
    });

    connection.on("jobCanceling", (data) => {
      let job: Job = JSON.parse(data);
      queryClient.setQueryData(`/job/${job.id}`, job);
      updateJobQueryCache(job);
      updateScriptJobQueryCache(job);
    });

    connection.on("jobWaitingOnFeedback", (feedback) => {
      let jobFeedback: JobFeedback = JSON.parse(feedback);
      queryClient.setQueryData(`/jobfeedback/${jobFeedback?.id}`, jobFeedback);
      queryClient.setQueryData(
        `/job/${jobFeedback?.job?.id}/feedback/incomplete`,
        jobFeedback
      );
      queryClient.refetchQueries(`/job/${jobFeedback?.job?.id}`);
      updateScriptJobQueryCache(
        queryClient.getQueryData(`/job/${jobFeedback?.job?.id}`)
      );
      queryClient.refetchQueries(`/job`);
    });

    connection.on("sendNotification", (notification) => {
      queryClient.refetchQueries('/notification', { stale: true, inactive: true, active: true })
      queryClient.refetchQueries("/notification/last", { stale: true, inactive: true, active: true })
    });

    connection.on("sendJobStreamOutput", (outputToStream) => {
      let jobStreamOutput: JobStreamOutput = JSON.parse(outputToStream);
      queryClient.setQueryData(
        ["jobs", { id: jobStreamOutput.JobId, type: "stream" }],
        jobStreamOutput
      );
    });

    connection.on("sendJobLog", (jobLog) => {
      let log = JSON.parse(jobLog);
      let jobLogOutput: JobLog = {
        log: log?.Log,
        id: log?.Id,
      };
      queryClient.setQueryData(`/job/${log?.Id}/log`, jobLogOutput);
      // queryClient.refetchQueries(`/job/${log?.Id}/log`);
    });

    connection.on("sendJobPipelineOutput", (pipelineOutput) => {
      let output = JSON.parse(pipelineOutput);
      if (output?.JsonData !== null || output?.JsonData !== undefined) {
        let pipeline: JobPipelineOutput = {
          data: output?.Data,
          id: output?.Id,
          jsonData: output?.JsonData,
          job: output?.Job,
        };
        queryClient.setQueryData(`/job/${output?.Id}/pipelineOutput`, [
          pipeline,
        ]);
      }
    });

    connection.on("tagDeleted", (data) => {
      let tagName: string = JSON.parse(data);

      queryClient
        .getQueryData<Script[]>("/script")
        ?.filter((script) => script?.tag?.some((tag) => tag?.name === tagName))
        ?.forEach(async (script) => {
          let tags = script?.tag?.filter((t) => t?.name !== tagName);

          mutateAsync({
            key: "/script",
            action: "update",
            ...{ ...script, tag: tags !== null ? tags : [] },
          });
        });
    });

    connection
      .start()
      .then((a) => {
        console.log("connection", connection);
      })
      .catch((err) => console.log("signalr error", err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutateAsync]);
}
