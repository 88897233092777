import { Image } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';

export const Props : Array<IComponentProperty>  = [
    { "name": "id", "type": ComponentPropertyType.String , "required": true, "description": "The ID for this alert", displayName: "Id" },
    { "name": "src", "type": ComponentPropertyType.String , "required": true, "description": "The URL to the image to display.", displayName: "URL" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Image, PowerShellUniversal',
        "src": "/admin/logo.png",
        "type": "Image",
        "id": uuidv4(),
    }
}

export function Component(props) {
    return <Image src={props.src}></Image>
}