import { Tag } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import * as Icons from '@ant-design/icons'

export const Props : Array<IComponentProperty>  = [
    { "name": "id", "type": ComponentPropertyType.String , "required": true, "description": "The ID for this tag", displayName: "Id" },
    { "name": "text", "type": ComponentPropertyType.String , "required": true, "description": "The message to display", displayName: "Text" },
    { "name": "color", "type": ComponentPropertyType.String , "required": false, "description": "The color of this tag.", displayName: "Color" },
    { "name": "icon", "type": ComponentPropertyType.Icon , "required": false, "description": "An icon to include in the tag.", displayName: "Icon" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Tag, PowerShellUniversal',
        "text": "Tag",
        "type": "Tag",
        "id": uuidv4(),
    }
}

export function Component(props) {
    return <Tag color={props.color} icon={props.icon && React.createElement(Icons[props.icon])}>{props.text}</Tag>
}