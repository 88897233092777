import React from "react";
import { useQuery } from "react-query3";
import { Navigate, Route } from "react-router";
import { Accessible, Settings } from "types";

export default function DefaultRoute() {
  const { data, isLoading } = useQuery<Settings>("/settings");
  const { data: userData, isLoading: isUserDataLoading } = useQuery<Accessible>(
    "/accessible"
  );

  if (isLoading || isUserDataLoading) return null;

  function setDefaultPath() {
    if (userData?.builtInRole) {
      return data?.defaultPage || "home";
    } else {
      return "automation/scripts";
    }
  }

  return (
    <Route
      path="/"
      element={
        <Navigate
          to={{
            pathname: setDefaultPath(),
          }}
        />
      }
    />
  );
}
