import { License } from "../../../types";
import { CustomSchema } from "./types";

export const licenseSchema: CustomSchema<License>[] = [
  {
    name: "licenseText",
    label: "License",
    component: "upload"
  },
];
