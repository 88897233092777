import { Table, Button, Card, Tooltip, Space } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import Papa from "papaparse";
import { ExportOutlined, ReloadOutlined } from '@ant-design/icons';
import PSUButton from './Button';
import useDataSource from '../DataSource';

export const PSUTableProps: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": false, "description": "The ID for this table", displayName: "Id" },
    { "name": "title", "type": ComponentPropertyType.String, "required": true, "description": "The title of this table.", displayName: "Title" },
    { "name": "allowExport", "type": ComponentPropertyType.Boolean, "required": false, "description": "Allow exporting from this table.", displayName: "Allow Export" },
    { "name": "allowManualRefresh", "type": ComponentPropertyType.Boolean, "required": false, "description": "Allow manual refresh of table data.", displayName: "Allow Manual Refresh" },
    { "name": "refreshInterval", "type": ComponentPropertyType.Number, "required": false, "description": "The number of seconds between refreshes of the table data.", displayName: "Refresh Interval" },
    { "name": "dataSource", "type": ComponentPropertyType.DataSource, "required": true, "description": <>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> data source for this table.</>, displayName: "Data Source", category: "Data Source" },
    { "name": "columns", "type": ComponentPropertyType.TableColumns, "required": false, "description": "Columns to display in this table. If you do not specify columns, all properties will be displayed.", displayName: "Columns", category: "Columns" },
]

export const NewPSUTable = () => {
    return {
        "title": "Table",
        "type": "Table",
        "id": uuidv4(),
        "$type": 'PowerShellUniversal.Components.Table, PowerShellUniversal'
    }
}

export function getExportFileBlob({ columns, data, fileType }) {
    if (fileType === "csv") {
        const exportData = data.map(item => {
            const row = {}
            columns.forEach(col => {
                row[col.title] = item[col.dataIndex]
            })
            return row;
        })

        const csvString = Papa.unparse(exportData);
        return new Blob([csvString], { type: "text/csv" });
    }

    return false;
}

export default function PSUTable(props) {
    const { refetch, data, isLoading } = useDataSource(props)

    var columns = props.columns;

    if (columns && columns.length !== 0) {
        columns = columns.map(x => {
            return {
                title: x.name,
                dataIndex: x.propertyName,
                key: x.name,
                render: (text, record) => {
                    if (x.type === 'button') {
                        return <PSUButton {...x.button} inputObject={record} onSuccess={() => refetch()} />
                    }
                    return String(record[x.propertyName]);
                },
                sorter: x.sortable ? (a, b) => ('' + a[x.propertyName]).localeCompare(b[x.propertyName]) : null,
            }
        })
    }
    else if (data && data.length !== 0) {
        columns = Object.keys(data[0]).map(x => {
            return {
                title: x,
                dataIndex: x,
                key: x,
                render: (text, record) => {
                    return String(record[x]);
                }
            }
        })
    }

    const onExport = (key) => {
        const fileName = `${props.title}.${key.key}`;
        const fileType = key.key;
        const blob = getExportFileBlob({ columns, data, fileType });
        if (blob) {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    const buttons = []
    if (props.allowExport) {
        buttons.push(
            <Tooltip title="Export">
                <Button disabled={isLoading} icon={<ExportOutlined />} onClick={() => onExport({ key: "csv" })}></Button>
            </Tooltip>
        )
    }

    if (props.allowManualRefresh) {
        buttons.push(
            <Tooltip title="Refresh">
                <Button disabled={isLoading} icon={<ReloadOutlined />} onClick={() => refetch()}></Button>
            </Tooltip>
        )
    }

    return (
        <Card title={props.title} extra={<Space>{buttons}</Space>}>
            <Table loading={isLoading} dataSource={data} columns={columns} />
        </Card>
    )

}