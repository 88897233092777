import React from "react";
import { Button, Checkbox, Form, Input, Popconfirm, Tabs, Typography, Select, Row, Col } from "antd";
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import ComponentPropertiesModal from "../ComponentProperties";

export default function TableColumns(props) {
    const { form } = props;
    const [fieldNames, setFieldNames] = React.useState(props.component.columns ? props.component.columns.map(x => x.name) : []);
    const [types, setTypes] = React.useState(props.component.columns ? props.component.columns.map(x => x.type) : []);
    const [visible, setVisible] = React.useState(false);

    const setName = (index, name) => {
        setFieldNames({ ...fieldNames, [index]: name });
    }

    const setType = (index, type) => {
        setTypes({ ...types, [index]: type });
    }

    const setButton = (key, button) => {
        const { columns } = form.getFieldsValue();
        Object.assign(columns[key], { button })

        form.setFieldsValue({ columns });
    }

    const buttonSettings = (field) => {
        if (types[field.name] !== "button") return;
        const { columns } = form.getFieldsValue();
        if (!columns) return;

        return (
            <Row>
                <Col span={6} />

                <Button icon={<SettingOutlined />} onClick={() => setVisible(true)}>Button Settings</Button>
                <ComponentPropertiesModal
                    description={<Typography.Text>You will be able to access the row data by referencing <Typography.Text code>$InputObject</Typography.Text> in your target.</Typography.Text>}
                    component={columns[field.key].button || { type: 'Button' }}
                    setComponent={(c) => setButton(field.key, c)}
                    visible={visible}
                    setVisible={setVisible} />
            </Row>
        )
    }

    const tableColumn = (field, remove) => {
        return (
            <Tabs.TabPane key={field.key} tab={fieldNames[field.name]} closable={false}  >
                <Form.Item
                    label="Type"
                    name={[field.name, 'type']}
                    fieldKey={[field.fieldKey, 'type']}>
                    <Select onChange={e => setType(field.name, e.toString())}>
                        <Select.Option value="basic">Basic</Select.Option>
                        <Select.Option value="button">Button</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Name"
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    help="The table column header name."
                    rules={[{ required: true, message: 'Missing name' }]}
                >
                    <Input onChange={(e) => setName(field.name, e.target.value)} />
                </Form.Item>
                {types[field.name] === "basic" && <Form.Item
                    label="Property Name"
                    help="The name of the property to display in the column."
                    name={[field.name, 'propertyName']}
                    fieldKey={[field.fieldKey, 'propertyName']}
                    rules={[{ required: true, message: 'Missing property name' }]}
                >
                    <Input />
                </Form.Item>}
                {buttonSettings(field)}
                <Form.Item
                    label="Sortable"
                    name={[field.name, 'sortable']}
                    fieldKey={[field.fieldKey, 'sortable']}
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label="Remove Column">
                    <Popconfirm title="Remove column?" onConfirm={() => remove(field.name)}>
                        <Button danger icon={<DeleteOutlined />}  ></Button>
                    </Popconfirm>
                </Form.Item>
            </Tabs.TabPane>
        )
    };

    const onEdit = (action, add, fields) => {
        if (action === "add") {
            add({
                key: fields.length + 1
            })
        }
    }


    return (
        <>
            <Typography.Paragraph>{props.description}</Typography.Paragraph>

            <Form.List name={["columns"]}>
                {(fields, { add, remove }) => (
                    <Tabs type="editable-card" onEdit={(targetKey, action) => onEdit(action, add, remove)}>
                        {fields.map(field => tableColumn(field, remove))}
                    </Tabs>
                )}
            </Form.List>
        </>
    )
}
