import { Template } from "../../../types";
import { CustomSchema } from "./types";

export const templateSchema: CustomSchema<Template>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
  },
  {
    name: "author",
    label: "Author",
    component: "input",
    required: true,
    uniqe: false,
  },
  {
    name: "description",
    label: "Description",
    component: "textarea",
    required: false,
    uniqe: false,
  },
  {
    name: "version",
    label: "Version",
    component: "input",
    required: true,
    uniqe: false,
  },
];
