import React from 'react';
import { Alert, Drawer, Space, Table, Typography } from "antd";
import { VariableContext } from './VariableContext';
import { Link } from 'react-router-dom';

const variableColumns = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (rowA, rowB) => rowA.name.localeCompare(rowB.name)
    },
    {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: (rowA, rowB) => rowA.value.localeCompare(rowB.value),
    },
    {
        title: 'Scope',
        dataIndex: 'context',
        key: 'context',
        filters: [
            {
                text: 'Global',
                value: 'global',
            },
            {
                text: 'Platform',
                value: 'platform',
            },
            {
                text: 'Page Data Source',
                value: 'pageDataSource',
            }
        ],
        onFilter: (value, record) => record.context === value,
        sorter: (rowA, rowB) => rowA.context.localeCompare(rowB.context),
        render: (text) => {
            if (text === 'platform') {
                return <Link to={"/admin/platform/variables"}>Platform</Link>
            }
            if (text === 'global') {
                return 'Global';
            }
            if (text === 'pageDataSource') {
                return 'Page';
            }
            if (text === 'temporary') {
                return 'Temporary';
            }
            if (text === 'session') {
                return 'Session';
            }
            return text;
        }
    }
]

export default function VariableDrawer({ visible, setVisible, page, licensed }) {
    if (!licensed) {
        return (
            <Drawer title="Variables" placement="right" onClose={() => setVisible(false)} visible={visible} width="40%">
                <Alert
                    type="info"
                    message={
                        <Space
                            align="center"
                            style={{ width: "100%", justifyContent: "space-between" }}
                        >
                            Variables require a license.
                            <Typography.Link
                                href="https://ironmansoftware.com/powershell-universal/?rel=product"
                                target="_blank"
                            >
                                Buy License.
                            </Typography.Link>
                        </Space>
                    }
                />
            </Drawer>
        );
    }

    return (
        <VariableContext.Consumer>{(context) => {
            return (
                <>
                    <Drawer title="Variables" placement="right" onClose={() => setVisible(false)} visible={visible} width="40%">
                        <Typography.Text>You can use variables within fields. Variables will be replaced once the page is rendered.</Typography.Text>
                        <Table dataSource={context.variables} columns={variableColumns} />
                    </Drawer>

                </>
            )
        }}</VariableContext.Consumer>
    )
}
