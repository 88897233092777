import { Settings } from "../../../types";
import { CustomSchema } from "./types";

export const ratelimitSettingsSchema: CustomSchema<Settings>[] = [
  {
    name: "rateLimitClientAllowList",
    label: "Clients",
    required: false,
    component: 'selectAsTags',
    uniqe: false,
    tooltip: "Client names that are excluded from rate limiting rules."
  },
  {
    name: "rateLimitEndpointAllowList",
    label: "Endpoints",
    component: 'selectAsTags',
    required: false,
    uniqe: false,
    tooltip: "Endpoints that are excluded from rate limiting rules."
  },
  {
    name: "rateLimitIpAddressAllowList",
    label: "IP Addresses",
    required: false,
    component: 'selectAsTags',
    uniqe: false,
    tooltip: "IP Addresses that are excluded from rate limiting rules."
  },
];
