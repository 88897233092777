import { Alert } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';

export const PSUAlertProps: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this alert", displayName: "Id" },
    { "name": "message", "type": ComponentPropertyType.String, "required": true, "description": "The message to display", displayName: "Message" },
    { "name": "description", "type": ComponentPropertyType.String, "required": false, "description": "The description to display.", displayName: "Description" },
    {
        "name": "alertType", "type": ComponentPropertyType.Select, "required": false, "description": "The type of alert to display.", displayName: "Type", options: [
            { value: null, text: "Default" },
            { value: "success", text: "Success" },
            { value: "info", text: "Info" },
            { value: "warning", text: "Warning" },
            { value: "error", text: "Error" }
        ]
    },
]

export const NewPSUAlert = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Alert, PowerShellUniversal',
        "message": "Alert!",
        "type": "Alert",
        "id": uuidv4(),
    }
}

export default function PSUAlert(props) {
    return (
        <Alert showIcon message={props.message} type={props.alertType || "info"} description={props.description || ""}></Alert>
    )

}