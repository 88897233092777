import "./public-path";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import "./index.css"
import "./Fonts.module.css";

sessionStorage.setItem("url", "/api/universal-automation");
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
