import { ColumnType } from "antd/es/table";
import { AxiosResponse } from "axios";
import { UseMutationOptions } from "react-query3";

export enum AccessControlTypes {
  None,
  View = 1 << 0,
  Edit = 1 << 1,
  Create = 1 << 2,
  Delete = 1 << 3,
  Execute = 1 << 4,
  All = ~(~0 << 4)
}

export type RoleGuardProps = {
  requiredRoles?: Array<string>;
  requiredAccessControls?: AccessControlTypes;
  accessControls?: AccessControlTypes;
  children?: any;
};


export type AppToken = {
  accessControls?: AccessControlTypes;
  identity: Identity;
  id: number;
  expiration: Date;
  created: Date;
  revoked: boolean;
  role: string;
  revokedDate: Date;
  token: string;
  resourceInfo?: ResourceInfo;
};

export type Notification = Partial<{
  id: number;
  createdTime: Date;
  createdBy: string;
  title: string;
  identity: Identity;
  description: string;
  job: Job;
  dashboard: Dashboard;
  script: Script;
  resourceInfo?: ResourceInfo;
}>;

export type Authentication = {
  accessControls?: AccessControlTypes;
  id: number;
  type: string;
  disabled: boolean;
  settings: string;
  resourceInfo?: ResourceInfo;
};

export enum DashboardStatus {
  Stopped,
  Started,
  StartFailed,
  Starting,
  Debugging,
}

export type Dashboard = Partial<{
  accessControls?: AccessControlTypes;
  authenticated: boolean;
  autoDeploy: boolean;
  baseUrl: string;
  content: string;
  dashboardComponents: Module[] | null;
  dashboardFramework: Framework;
  disableAutoStart: boolean;
  debug: boolean;
  environment: string;
  filePath: string;
  id: number;
  grantAppToken: boolean;
  deployed: boolean;
  lastDeploy: string;
  name: string;
  notes: string;
  processId: number;
  processName: string;
  resourceInfo?: ResourceInfo;
  role: string[];
  status: DashboardStatus;
  sessionTimeout: number;
  tag: Tag[];
  maintenance: boolean;
}>;

export type DashboardTotalSession = {
  resourceInfo?: ResourceInfo;
} & Number;

export type DashboardLog = {
  id: number;
  log: string;
  resourceInfo?: ResourceInfo;
};

export type DashboardDiagnostics = {
  memory: number;
  memoryHistory: number[];
  sessions: DashboardSession[];
  endpoints: DashboardEndpoint[];
  resourceInfo?: ResourceInfo;
};

export type DashboardEndpoint = {
  accessControls?: AccessControlTypes;
  id: string;
  averageExecutionTime: number;
  resourceInfo?: ResourceInfo;
};

export type Framework = {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  version: string;
  path: string;
  latest: boolean;
  resourceInfo?: ResourceInfo;
};

export type Module = {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  version: string;
  path: string;
  description: string;
  environment: string;
  resourceInfo?: ResourceInfo;
};

export type DashboardMarketplaceOverview = {
  newPackages?: DashboardMarketplaceModule[];
  mostDownloadedPackages?: DashboardMarketplaceModule[];
};

export interface SaveModuleProps {
  name: string;
  version: string;
  installed: boolean;
  mutationOptions?: UseMutationOptions<
    AxiosResponse<any>,
    unknown,
    { name: string; version: string },
    unknown
  >;
}

export type DashboardMarketplaceBrowsePage = {
  modules?: DashboardMarketplaceModule[];
  currentPage: number;
  totalPages: number;
  type: string;
  orderBy: string;
  totalItems: number;
  fromSearch?: boolean;
};

export type DashboardMarketplaceModule = {
  id: number;
  nuGetId: string;
  version: string;
  projectUrl: string;
  title: string;
  tags: string;
  summary: string;
  requireLicenseAcceptance: boolean;
  owners: string;
  published: Date;
  reportAbuseUrl: string;
  licenseUrl: string;
  iconUrl: string;
  downloadCount: number;
  description: string;
  authors: string;
  readme: string;
  installed: boolean;
  type: MarketplaceModuleType;
  fromSearch?: boolean;
  resourceInfo?: ResourceInfo;
};

export type Environment = {
  id: number;
  name: string;
  path: string;
  arguments?: string[];
  modules?: string[];
  variables: string[];
  pSModulePath?: string[];
  persistentRunspace: boolean;
  version: string;
  resourceInfo?: ResourceInfo;

};

export enum MarketplaceModuleType {
  Dashboard,
  Control,
  GitHubRepo,
  Tool,
}

export type DashboardSession = {
  accessControls?: AccessControlTypes;
  id: string;
  lastTouched: Date;
  userName: string;
  endpoints: Array<DashboardEndpoint>;
  resourceInfo?: ResourceInfo;
};

export declare const MethodName: ["GET", "POST", "DELETE", "PUT", "PATCH", "OPTIONS"];
export declare type MethodType = typeof MethodName[number];

export type Endpoint = {
  accessControls: AccessControlTypes;
  id?: number;
  url?: string;
  method?: MethodType;
  scriptBlock?: string;
  authentication?: boolean;
  role?: Array<string> | null | undefined;
  regEx?: boolean;
  parts?: Part[];
  description: string;
  editable?: boolean;
  fullUrl: string;
  tag: Tag[];
  resourceInfo?: ResourceInfo;
};

export type Part = {
  isVariable: boolean;
  value: string;
};

export enum Feature {
  Api,
  Automation,
  Dashboard,
}

export type License = {
  accessControls?: AccessControlTypes;
  id: number;
  product: string;
  startDate: Date;
  endDate: Date;
  licensee: string;
  seats: number;
  licenseText: string;
  status: string;
  developer: boolean;
  resourceInfo?: ResourceInfo;
};

export type LicenseStatus = {
  product: string;
  expired: boolean;
  licensed: boolean;
  seats: number;
};

export type Identity = {
  accessControls?: AccessControlTypes;
  name: string;
  id: number;
  role?: Role;
  roleName?: string;
  resourceInfo?: ResourceInfo;
};

export type PowerShellVersion = {
  id: number;
  version: string;
  path: string;
  resourceInfo?: ResourceInfo;
};

export type RateLimit = {
  accessControls?: AccessControlTypes;
  endpoint: string;
  id?: number;
  limit: number;
  period: string;
  resourceInfo?: ResourceInfo;
};

export interface Role {
  accessControls?: AccessControlTypes;
  name: string;
  description?: string;
  policy: string;
  id?: number;
  resourceInfo?: ResourceInfo;
}

export type Tag = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  color: any;
  description: string;
  editable: boolean;
  resourceInfo?: ResourceInfo;
}>;

type Computer = {
  id: number;
  name: string;
};

export enum JobStatus {
  Queued,
  Running,
  Completed,
  Failed,
  WaitingOnFeedback,
  Canceled,
  Canceling,
  Historical,
  Active,
  TimedOut
}

export interface ResourceInfo {
  parent?: string;
  self: string;
  schemaName: string;
}

export type Job = Partial<{
  feedback: JobFeedback;
  runAs: string;
  activity: null;
  agent: null;
  appToken: AppToken;
  children: null;
  computer: Computer;
  computerName: string;
  createdTime: Date;
  credential: any;
  currentOperation: string;
  debug: boolean;
  endTime: Date;
  environment: string | null;
  errorAction: ActionPreferenceType;
  id: number;
  identity: Identity;
  jobOutput: JobOutput;
  notes: string;
  output: string;
  parameters: JobParameter[];
  parentJob: Job;
  parentLineNumber: number;
  percentComplete: number;
  port: number;
  processId: number;
  runspaceId: number;
  scheduleId: number;
  scriptFullPath: string;
  script: Script;
  isScriptDeleted: boolean;
  secondsRemaining: number;
  startTime: Date;
  status: JobStatus;
  statusDescription: string;
  trigger: string;
  triggered: boolean;
  retryCount: number;
  resource: ResourceInfo;
}>;

export type JobParameter = Partial<{
  id: number;
  name: string;
  displayName: string;
  type: string;
  job: Job;
  value: string;
  vault: string;
  variable: Variable;
  displayType: ScriptParameterType;
  resourceInfo?: ResourceInfo;
}>;

export type JobContext = Partial<{
  jobParameters: JobParameter[];
  parentJobId: number;
  lineNumber: number;
  debug: boolean;
  gotojob: boolean;
  computer: string;
  errorAction: ActionPreferenceType;
  environment: string | null;
  notes: string;
  credential: string | null;
  event: boolean;
  resourceInfo?: ResourceInfo;
}>;

export interface JobFeedback {
  id: number;
  data: string;
  message: string;
  complete: boolean;
  identity: Identity;
  job: Job;
  resourceInfo: ResourceInfo;
}

export type JobOutput = {
  Id: number;
  Message: string;
  Type: JobOutputType;
  Data: string;
  Job: Job;
  Timestamp: Date;
  resourceInfo?: ResourceInfo;
};

export type JobStreamOutput = Partial<{
  JobId: number;
  Type: JobOutputType;
  Data: string;
  Timestamp: Date;
  resourceInfo?: ResourceInfo;
}>;

export type JobError = Partial<{
  type: JobOutputType;
  message: string;
  timestamp: string;
  resourceInfo?: ResourceInfo;
}>;

export enum JobOutputType {
  Information,
  Verbose,
  Debug,
  Warning,
  Error,
  Progress,
}

export type JobLog = {
  id: number;
  log: string;
  resourceInfo?: ResourceInfo;
};

export type JobPipelineOutput = {
  accessControls?: AccessControlTypes;
  id: number;
  data: string;
  jsonData: string;
  job: Job;
  resourceInfo?: ResourceInfo;
};

export type JobPipelineOutputData = {
  accessControls?: AccessControlTypes;
  title: string;
  name: string;
  value: string;
  key: string;
  isLeaf: boolean;
  selectable: boolean;
  resourceInfo?: ResourceInfo;
};

export type NotificationQuery = Partial<{
  hasPageProperties: boolean;
  orderBy: string;
  orderDirection: number;
  page: Notification[];
  skip: number;
  take: number;
  total: number;
}>;


export type JobQuery = Partial<{
  hasPageProperties: boolean;
  orderBy: string;
  orderDirection: number;
  page: Job[];
  skip: number;
  status: null;
  take: number;
  total: number;
}>;

export type Folder = {
  path: string;
  type: FolderType;
};

export enum FolderType {
  Unknown,
  Script,
  Dashboard,
  Page,
  Module
}

export enum ActionPreferenceType {
  SilentlyContinue,
  Stop,
  Continue,
  Inquire,
  Ignore,
  Suspend,
  Break,
}

export enum ScriptStatus {
  Draft,
  Pending_Review,
  Published,
  Disabled,
}

export enum ScriptParameterType {
  String,
  Integer,
  Boolean,
  DateTime,
  Select,
  Credential,
  StringArray,
}

export type ScriptHistory = {
  commitId: string;
  content: string;
  author: string;
  message: string;
  timestamp: Date;
};

export type ScriptTag = {
  id: number;
  tag: Tag;
  script: Script;
};

export type ScriptParameter = {
  id: number;
  name: string;
  displayName: string;
  type: string;
  script: Script;
  defaultValue: any;
  required: boolean;
  displayType: ScriptParameterType;
  validValues: string[];
  helpText: string;
};

export type Script = Partial<{
  accessControls?: AccessControlTypes;
  commitId: string;
  commitNotes: string;
  concurrentJobs: number;
  content: string;
  createdTime: Date;
  credential: string;
  debug: boolean;
  description: string;
  disableManualInvocation: boolean;
  environment: string | null;
  errorAction: number;
  folder: Folder;
  fullPath: string;
  id: number;
  identity: Identity;
  informationAction: number;
  manualTime: number;
  maxHistory: number;
  name: string;
  requiredPowerShellVersion: string;
  scriptParameters: ScriptParameter[];
  status: number;
  tag: Tag[];
  timeOut: number;
  jobs: Job[];
  verbose: boolean;
  resourceInfo?: ResourceInfo;
}>;

export type Template = Partial<{
  author: string;
  name: string;
  description: string;
  version: string;
  variables: Array<Variable>;
  resourceInfo?: ResourceInfo;
}>;

export enum LogLevel {
  Debug,
  Information,
  Warning,
  Error,
}

export type Settings = Partial<{
  autoReload: boolean;
  apiEnvironment: string;
  concurrentJobLimit: number;
  databasePath: string;
  defaultEnvironment: string;
  defaultPage: string;
  defaultPowerShellVersion: PowerShellVersion | undefined;
  disableAutoReload: boolean;
  disableUpdateCheck: boolean;
  groomDays: number | undefined;
  hideApi: boolean;
  hideAutomation: boolean;
  hideDashboard: boolean;
  hideHomePage: boolean;
  id: number;
  loggingFilePath: string;
  logLevel: LogLevel;
  microsoftLogLevel: LogLevel;
  rateLimitClientAllowList: string[] | undefined;
  rateLimitEndpointAllowList: string[] | undefined;
  rateLimitIpAddressAllowList: string[] | undefined;
  repositoryPath: string;
  securityEnvironment: string | undefined;
  securityPowerShellVersion: string | undefined;
  sessionTimeout: number;
  showDevTools: boolean;
  telemetry: boolean;
  gitSyncEnabled: boolean;
  gitRemote: string;
  gitBranch: string;
  gitSyncBehavior: GitSyncBehavior;
  scriptBaseFolder: string;
}>;

export enum GitSyncBehavior {
  TwoWay,
  OneWay
}

export interface Accessible {
  username: string;
  roles: string[];
  builtInRole: boolean;
  accessControls: userAccessControls;
}

export interface userAccessControls {
  scripts: AccessControlTypes;
}

export type Stats = {
  jobsRunToday: number;
  JobsWaitingOnFeedback: number;
  jobsFailedToday: number;
  runningJobs: number;
  endpoints: number;
  runningDashboards: number;
  dashboardSessions: number;
  licensed: boolean;
  licensedName: string;
  licenseExpiration: string;
  defaultEnvironment: string;
  apiEnvironment: string;
  securityEnvironment: string;
  pages: number;
  scheduledJobs: number;
};

export interface Sso {
  enabled: boolean;
  resourceInfo?: ResourceInfo;
}

export interface PublishedFolder {
  accessControls?: AccessControlTypes;
  id: number;
  path: string;
  requestPath: string;
  authentication: boolean;
  role: string[] | null;
  defaultDocument: string[];
  resourceInfo?: ResourceInfo;
}

export type Update = {
  version?: string;
  releaseDate?: string;
  winZipUrl?: string;
  winMsiUrl?: string;
  linuxZipUrl?: string;
  osxZipUrl?: string;
  windows?: boolean;
  linux?: boolean;
  osx?: boolean;
  notes?: string;
};

declare const ThemeModeTypes: ["default", "dark", "compact"];
export declare type ThemeModeType = typeof ThemeModeTypes[number];

export type Theme = {
  mode: ThemeModeType;
  color: string;
};

export interface Variable {
  accessControls?: AccessControlTypes;
  id?: number;
  name: string;
  value: string;
  type?: string;
  createdTime?: Date;
  password?: any;
  secret?: boolean;
  updatedTime?: Date;
  userName?: any;
  vault?: string;
  editable?: boolean;
  description?: string;
  resourceInfo?: ResourceInfo;
}

export type ScheduleParameter = {
  name: string;
  value: string;
  type: ScriptParameterType;
};

export declare const TriggerEventTypes: [
  "JobCanceled",
  "JobFailed",
  "JobCompleted",
  "JobStarted",
  "JobFeedbackRequested",
  "ServerStarted",
  "ServerStopped",
  "DashboardStarted",
  "DashboardStopped"
];

export declare type TriggerEventType = typeof TriggerEventTypes[number];

export type Trigger = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  script: string;
  eventType: string;
  environment: string;
  parameter: ScheduleParameter[];
  triggerScript: string;
  dashboard: string;
  editable: boolean;
  delay: number;
  resourceInfo?: ResourceInfo;
}>;

export type Schedule = Partial<{
  accessControls?: AccessControlTypes;
  id: number;
  identity: Identity;
  appToken: AppToken;
  cron: string;
  nextExecution: Date;
  description: string;
  script: string;
  timeZoneString: string;
  continuous: boolean;
  delay: number | string;
  credential: string;
  oneTime: string;
  environment: string | null;
  type: "cron" | "continuous" | "delay" | "oneTime";
  datetime: Date;
  parameters: ScheduleParameter[];
  name: string;
  paused: boolean;
  resourceInfo?: ResourceInfo;
}>;

export enum AuthenticationMethodType {
  Form,
}

export interface Configuration extends String {
  accessControls?: AccessControlTypes;
  resourceInfo?: ResourceInfo;
}

export type PSUTypes =
  | undefined
  | Configuration
  | Dashboard
  | Script
  | Framework
  | PublishedFolder
  | Trigger
  | RateLimit
  | Endpoint
  | DashboardEndpoint
  | DashboardSession
  | Module
  | Tag
  | JobPipelineOutput
  | JobPipelineOutputData
  | Role
  | Variable
  | Schedule
  | Identity
  | AppToken
  | Authentication
  | GitStatus
  | License
  | LoginPage
  | Page;


export interface CustomColumns<T> extends ColumnType<T> {
  editable?: boolean;
  sortable?: boolean;
}

export interface GitStatus {
  accessControls?: AccessControlTypes;
  id: number;
  commitId: string;
  timeStamp: Date;
  syncTime: Date;
  changes: number;
  location: string;
  remote: string;
  result: GitStatusResult;
  resultMessage: string;
  resourceInfo?: ResourceInfo;
}

export enum GitStatusResult {
  Running,
  Success,
  Failed,
}

export interface Page {
  accessControls?: AccessControlTypes;
  id: number;
  name: string;
  description: string;
  roles: string[];
  authenticated: boolean;
  components: any;
  layout: any;
  icon: string;
  showNavigation: boolean;
  showInNavigation: boolean;
  hideHeader: boolean;
  dataSource: string;
  dataSourceType: string;
  url: string;
  resourceInfo?: ResourceInfo;
}

export type LoginPage = {
  id: number;
  image: string;
  title: string;
  primaryColor: string;
  secondaryColor: string;
  primaryFontColor: string;
  secondaryFontColor: string;
  headerFontColor: string;
  headerColor: string;
  copyright: string;
  resourceInfo?: ResourceInfo;
  links: Array<LoginPageLink>;
  accessControls?: AccessControlTypes;
  defaultLoginPage: boolean;
  defaultAuthentication: boolean;
  cssStylesheet: Array<string>;
};

export type LoginPageLink = {
  text: string;
  url: string;
}
