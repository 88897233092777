import React from "react";
import { Select, Form as AntForm } from "antd";
import { ValidateStatus } from "antd/lib/form/FormItem";
import getComponent from "./getComponent";
import { getSchema } from "./getSchema";
import { CustomFormProps, CustomSchema, SchemaName } from "./types";
import { RuleObject } from "antd/lib/form";
import queryClient from "../../utils/queryClient";
import { AccessControlTypes, Endpoint, PublishedFolder } from "types";
import { useQuery } from "react-query3";
import RoleGuard from "components/standalone/role-guard";
import { callAll } from "components/utils/utils";
import useAppStore from "components/context/app/Hooks";

const { Item } = AntForm;
const { Option } = Select;

type FormContextProps = {
  validateStatus: "" | "success" | "warning" | "error" | "validating";
  setValidateStatus: React.Dispatch<
    React.SetStateAction<"" | "success" | "warning" | "error" | "validating">
  >;
  getComponent: (component: string) => any;
  getSchame: <T>(schemaName: SchemaName) => CustomSchema<T>[];
  schemaName: string;
  schema: CustomSchema<any>[];
  roles: string[];
};

type SubmitButtonProps = {
  requiredRoles: Array<string>;
  requiredAccessControls: AccessControlTypes;
  accessControls?: AccessControlTypes;
  children: any;
};

const FormContext = React.createContext<FormContextProps>(null);
FormContext.displayName = "Form";

const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

// Main Form Container
function Form<T>({ children, schemaName }) {
  const [validateStatus, setValidateStatus] = React.useState<ValidateStatus>();

  const {
    userData: { roles },
  } = useAppStore();
  const schema = React.useMemo(() => getSchema<T>(schemaName), [schemaName]);
  const value = React.useMemo(
    () => ({
      validateStatus,
      setValidateStatus,
      getComponent,
      getSchame: getSchema,
      schemaName,
      schema,
      roles,
    }),
    [validateStatus, setValidateStatus, schemaName, schema, roles]
  );

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

function Field<T>({ component, ...props }: CustomSchema<T>) {
  const { setValidateStatus, roles } = React.useContext(FormContext);
  const Component = component && getComponent(component);

  const { data: options } = useQuery<any[]>(`/${props?.selectOptions}`, {
    enabled: !!props?.selectOptions,
    refetchOnWindowFocus: false,
  });

  return (!props.requiredRole || (props.requiredRole && roles.indexOf(props.requiredRole) !== -1)) && (
    <Item
      {...props}
      preserve={false}
      onReset={() => setValidateStatus(undefined)}
    >
      {!component ? (
        props?.children
      ) : component !== "select" ? (
        <Component form={props.form} />
      ) : (
        <Component>
          <Option key="none" value={""}>
            {null}
          </Option>
          {options?.map((option) => (
            <Option key={option?.id} value={option?.name}>
              {option?.name}
            </Option>
          ))}
        </Component>
      )}
    </Item>
  );
}

function FormContentBase<T>({ ...props }: CustomFormProps<T>) {
  const {
    schema,
    schemaName,
    setValidateStatus,
    validateStatus,
  } = React.useContext(FormContext);

  const collection = queryClient.getQueryData<any[]>(`/${schemaName}`);

  function isValueChange(value: string, field: CustomSchema<T>) {
    if (props?.defaultValues) {
      return props?.defaultValues[field?.name?.toString()] === value;
    }
    if (field?.initialValue && field?.initialValue !== null) {
      return field?.initialValue === value;
    }
    return null;
  }

  function isResourceExists(value, field) {
    let currentValue =
      schemaName === "script"
        ? `${value}.ps1`?.toLowerCase()
        : value.toLowerCase();
    if (field?.uniqe) {
      if (schemaName === "endpoint") {
        return collection?.find(
          (endpoint: Endpoint) =>
            endpoint.url?.toLowerCase() === currentValue &&
            endpoint.method === props?.form.getFieldValue("method")
        );
      }
      if (schemaName === "publishedfolder") {
        return collection?.find(
          (folder: PublishedFolder) =>
            folder.path?.toLowerCase() === currentValue &&
            folder.requestPath === props?.form.getFieldValue("requestPath")
        );
      }
      else {
        return collection?.some(
          (item) => item[field?.name]?.toLowerCase() === currentValue
        );
      }
    } else return false;
  }

  function validateValue(value, field) {
    if (null === value || value === "" || value === undefined) {
      setValidateStatus("error");
      return Promise.reject(` ${field?.name} can't be empty`);
    } else if (!!isValueChange(value, field)) {
      setValidateStatus(undefined);
      return Promise.resolve();
    } else {
      setValidateStatus("validating");
      if (isResourceExists(value, field)) {
        setValidateStatus("error");
        return Promise.reject(
          `object with that ${field?.name} already exists, ${field?.name} must be unique.`
        );
      } else {
        setValidateStatus("success");
        return Promise.resolve("cool");
      }
    }
  }

  function setRule(field: CustomSchema<T>) {
    if (!!field?.required) {
      return {
        validateStatus: validateStatus,
        rules: [
          {
            required: true,
            validator: (_: RuleObject, value: string) => validateValue(value, field),
          },
        ],
      };
    }
  }

  function setInitialValue(field: CustomSchema<T>) {
    return props?.defaultValues &&
      props?.defaultValues[field?.name?.toString()] !== null
      ? props?.defaultValues[field?.name?.toString()]
      : field?.initialValue
        ? field?.initialValue
        : field?.children;
  }

  function setHidden(field: CustomSchema<T>) {
    return props?.inEditMode && field?.hideInEditMode;
  }

  return (
    <AntForm
      {...props}
      {...formItemLayout}
      labelAlign="left"
      preserve={false}
      requiredMark={false}
    >
      {schema?.map(
        (field): JSX.Element => {
          return (
            <Field
              {...field}
              {...setRule(field)}
              hidden={setHidden(field)}
              initialValue={setInitialValue(field)}
              form={props.form}
            />
          );
        }
      )}
    </AntForm>
  );
}

function FormBase<T>({ children, ...props }: CustomFormProps<T>) {
  return <AntForm
    {...props}
    {...formItemLayout}
    labelAlign="left"
    preserve={false}
    requiredMark={false}
  >
    {children}
  </AntForm>
}



function SubmitButton({ children: child, requiredRoles, requiredAccessControls, accessControls }: SubmitButtonProps) {
  return (
    <RoleGuard requiredRoles={requiredRoles} requiredAccessControls={requiredAccessControls} accessControls={accessControls}>
      {React.cloneElement(child, {
        type: child?.props?.type,
        icon: child?.props?.icon,
        onClick: callAll(child?.props?.onClick),
      })}
    </RoleGuard>
  );
}

export { Form, Field, FormContentBase, SubmitButton, FormBase };
