import React from "react";
import { Menu, Layout, Space, Typography, Button, Dropdown } from "antd";
import { UserOutlined, LogoutOutlined, BarcodeOutlined } from "@ant-design/icons";
import { useQuery } from "react-query3";
import Logo from "../ui/Logo";
import useAppStore from "../context/app/Hooks";
import queryClient from "components/utils/queryClient";
import ThemeToggle from "components/ui/theme/themeToggle";
import Notification from "components/ui/Notification";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useNavigate } from "react-router";

const UAMenuHeader = () => {
  const { userData, version } = useAppStore();
  const navigate = useNavigate();
  const { data: authType } = useQuery<string>("/authmethod");
  const { refetch: logoff } = useQuery<string>("/signout", {
    enabled: false,
    onSuccess: () => queryClient.clear(),
  });

  const { currentTheme } = useThemeSwitcher()
  const onClick = (key) => {
    if (key === "signout") {
      logoff().then(() => (window.location.href = "/login"))
    }

    if (key === "tokens") {
      navigate("/admin/security/tokens")
    }
  };

  return (
    <Layout.Header

      style={{
        color: currentTheme === 'light' ? "#000000" : "#ffffff",
        backgroundColor: currentTheme === 'light' ? '#fff' : '#1f1f1f',
        padding: "0px 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Logo />
      <Space>
        <Typography.Text
          style={{ verticalAlign: "sub", marginRight: 24 }}
        >
          {version}
        </Typography.Text>
        <ThemeToggle
          checkedChildren="Dark"
          unCheckedChildren="Light"
          size="small"
        />
        <Notification />
        <Dropdown
          placement="bottomCenter"
          //@ts-ignore
          destroyPopupOnHide={true}
          trigger={["click"]}
          overlay={() => (
            <Menu onClick={({ key }) => onClick(key)}>
              {(userData?.roles.includes("Administrator") || userData?.roles.includes("Operator") || userData?.roles.includes("Execute")) && (
                <Menu.Item key="tokens" icon={<BarcodeOutlined />}>
                  Tokens
                </Menu.Item>
              )}
              {authType === "Cookies" && (
                <Menu.Item key="signout" icon={<LogoutOutlined />}>
                  Sign Out
                </Menu.Item>
              )}
            </Menu>
          )}
        >
          <Button type="text" icon={<UserOutlined />}>{userData?.username}</Button>
        </Dropdown>
      </Space>
    </Layout.Header>
  );
};

export default UAMenuHeader;
