import React from 'react';
import { Variable } from 'types';
import { IComponentProperty } from './ComponentProperty';

export type PageVariable = {
    name: string;
    value: string;
    session: boolean;
    context: string;
}

export const VariableContextDefault = {
    variables: new Array<PageVariable>(),
    credentials: new Array<Variable>(),
    setVariable: (variable: PageVariable) => { },
    replaceVariables: (context, props, properties: Array<IComponentProperty>): any => { return {} }
}

export const VariableContext = React.createContext(VariableContextDefault);