import React from "react";
import { callAll } from "../utils";
import { Button as AntButton } from "antd";
import {
  CloseCircleFilled,
  DeleteFilled,
  EditFilled,
  SaveFilled,
} from "@ant-design/icons";

const Save = {
  type: "text",
  icon: <SaveFilled />,
};

const Cancel = {
  type: "text",
  icon: <CloseCircleFilled />,
};

const Edit = {
  type: "text",
  icon: <EditFilled />,
};

const Delete = {
  type: "text",
  danger: true,
  icon: <DeleteFilled />,
};

const Actions = {
  save: Save,
  edit: Edit,
  cancel: Cancel,
  delete: Delete,
};

export function useActionButton() {
    
  const getButtonProps = ({
    //@ts-ignore
    onClick = () => {},
    action = "save" || "edit" || "cancel" || "delete",
    ...props
  }) => ({
    onClick: callAll(onClick),
    ...Actions[action],
    ...props,
  });

  const Button = (props) => <AntButton {...getButtonProps({ ...props })} />;

  return {
    getButtonProps,
    Button,
  };
}
