import useAppStore from "components/context/app/Hooks";
import React from "react";
import { RoleGuardProps } from "types";



const RoleGuard: React.FunctionComponent<RoleGuardProps> = (props) => {
  const { requiredRoles, children, requiredAccessControls, accessControls } = props;
  const { userData } = useAppStore();
  var found = false;

  if (requiredRoles) {
    for (let i = 0; i < userData?.roles?.length; i++) {
      if (requiredRoles.indexOf(userData?.roles[i]) !== -1) {
        found = true;
        break;
      }
    }
  }

  if (!found && requiredAccessControls && accessControls) {
    found = ((accessControls & requiredAccessControls) === requiredAccessControls);
  }

  return found && children;
};

export default RoleGuard;
