import React, { Fragment } from "react";
import { AccessControlTypes, AppToken } from "../../types";
import { Button } from "antd";
import {
  ModalContent,
  ModalOpenButton,
  useModalContext,
} from "components/ui/Modal";
import { Form, FormContentBase } from "components/ui/Form/Form";
import useAppStore from "components/context/app/Hooks";

export default function NewAppTokenModal() {
  const appContext = useAppStore();

  let userRoles: Array<string>;
  if (appContext.userData) {
    userRoles = appContext.userData.roles;
  }
  const { onOk, form } = useModalContext();

  function handleOk() {
    onOk("/apptoken/grant", userRoles?.includes("Administrator") ? "create" : "get", {
      revoked: false,
      id: 0,
    } as AppToken);
  }

  return (
    <Fragment>
      <ModalOpenButton requiredRoles={["Administrator", "Operator", "Execute"]} requiredAccessControls={AccessControlTypes.Create}>
        <Button children="Add New App Token" />
      </ModalOpenButton>
      <ModalContent title="New App Token" onClickOk={handleOk} formName="create_token_form">
        <Form schemaName="token">
          <FormContentBase
            name="create_token_form"
            form={form}
            preserve={false}
          />
        </Form>
      </ModalContent>
    </Fragment>
  );
}
