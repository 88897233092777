import { environmentSchame } from "./environmentSchame";
import { scriptSchame } from "./scriptSchame";
import { tagSchame } from "./tagSchame";
import { triggerSchame } from "./triggerSchame";
import { licenseSchema } from "./licenseSchema";
import { roleSchema } from "./roleSchema";
import { identitySchema } from "./identitySchema";
import { tokenSchema } from "./tokenSchema";
import { scheduleSchema } from "./scheduleSchema";
import { variableSchema } from "./variableSchema";
import { endpointSchema } from "./endpointSchema";
import { ratelimitSchema } from "./ratelimitSchema";
import { ratelimitSettingsSchema } from "./ratelimitSettingsSchema";
import { frameworkSchema } from "./frameworkSchema";
import { dashboardSchema } from "./dashboardSchema";
import { publishedFolderSchema } from "./publishedFolderSchema";
import { feedbackSchema } from "./feedbackSchema";
import { templateSchema } from "./templateSchema";
import { pageSchema } from "./pageSchema";
import { folderSchema } from "./folderSchema";

export const schemas = {
  endpoint: endpointSchema,
  tag: tagSchame,
  script: scriptSchame,
  trigger: triggerSchame,
  environment: environmentSchame,
  license: licenseSchema,
  role: roleSchema,
  identity: identitySchema,
  token: tokenSchema,
  schedule: scheduleSchema,
  variable: variableSchema,
  ratelimit: ratelimitSchema,
  ratelimitSettings: ratelimitSettingsSchema,
  dashboardframework: frameworkSchema,
  dashboard: dashboardSchema,
  publishedfolder: publishedFolderSchema,
  jobfeedback: feedbackSchema,
  template: templateSchema,
  page: pageSchema,
  folder: folderSchema
};
