import { Role } from "../../../types";
import { CustomSchema } from "./types";

export const roleSchema: CustomSchema<Role>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
  },
];
