import React from "react";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { useQuery } from "react-query3";
import { Endpoint, Script } from "./../../../types";

export default function DataSourceSelector(props) {
    const { form } = props;
    const { data, isLoading } = useQuery<Script[]>("/script");
    const { data: apis, isLoading: apisLoading } = useQuery<Endpoint[]>("/endpoint");
    const [dataSourceType, setDataSourceType] = React.useState(props.component?.dataSourceType || "script");
    const [searchText, setSearchText] = React.useState("");

    const onClear = () => {
        form.setFieldsValue({
            dataSource: undefined,
            dataSourceType: undefined,
        });

        setDataSourceType("script");
    }

    return (
        <>
            <Typography.Text strong>Data Source</Typography.Text>
            <Typography.Paragraph>{props.description}</Typography.Paragraph>

            <Form.Item
                label="Type"
                name="dataSourceType"
                rules={[{ required: props.required }]}
            >
                <Select defaultActiveFirstOption={dataSourceType} onChange={(value) => setDataSourceType(value)}>
                    <Select.Option key="api" value="api">API</Select.Option>
                    <Select.Option key="script" value="script">Script</Select.Option>
                </Select>
            </Form.Item>

            {dataSourceType === "script" ? <>
                <Form.Item
                    label="Script"
                    name="dataSource"
                    help="Selecting a script will return the script's last job's output."
                    rules={[{ required: props.required }]}
                >
                    <Select {...props} loading={isLoading}>
                        {data?.map((script) => (
                            <Select.Option key={script.fullPath} value={script.fullPath}>{script.name}</Select.Option>
                        ))}
                    </Select>
                </Form.Item></> : <></>}

            {dataSourceType === "api" ? <>
                <Form.Item
                    label="API"
                    name="dataSource"
                    rules={[{ required: props.required }]}
                    help="Selecting an API will call the endpoint."
                >
                    <Select {...props} loading={apisLoading} onSearch={(val) => setSearchText(val)} showSearch>
                        {searchText !== '' && <Select.Option key="search" value={searchText}>{searchText}</Select.Option>}
                        {apis?.filter(x => x.method === 'GET').map((endpoint) => (
                            <Select.Option key={endpoint.id} value={endpoint.url}>{`${endpoint.url}`}</Select.Option>
                        ))}
                    </Select>
                </Form.Item></> : <></>}
            {!props.required && (
                <Row>
                    <Col offset={16}>
                        <Button onClick={onClear}>Clear</Button>
                    </Col>
                </Row>
            )}


        </>
    )
}
