import React from "react";
import { Button, Form, Input, Space, Typography } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export default function TargetParameters(props) {
    return (
        <>
            <Typography.Text strong>Parameters</Typography.Text>
            <Typography.Paragraph>Parameters to pass to the script or API.</Typography.Paragraph>
            <Form.List name={["target", "parameters"]}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                            <Space key={field.key} align="baseline">
                                <Form.Item
                                    {...field}
                                    label="Name"
                                    name={[field.name, 'name']}
                                    fieldKey={[field.fieldKey, 'name']}
                                    rules={[{ required: true, message: 'Missing name' }]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    {...field}
                                    label="Value"
                                    name={[field.name, 'value']}
                                    fieldKey={[field.fieldKey, 'value']}
                                    rules={[{ required: true, message: 'Missing value' }]}
                                >
                                    <Input />
                                </Form.Item>

                                <MinusCircleOutlined onClick={() => remove(field.name)} />
                            </Space>
                        ))}

                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Add parameter
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </>
    )
}
