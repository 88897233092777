import { Button, message } from 'antd';
import { IComponentProperty, ComponentPropertyType } from './../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import * as Icons from '@ant-design/icons';
import { Link } from 'react-router-dom';

export const PSUButtonProps: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this button", displayName: "Id" },
    { "name": "text", "type": ComponentPropertyType.String, "required": false, "description": "The text for this button", displayName: "Text" },
    { "name": "icon", "type": ComponentPropertyType.Icon, "required": false, "description": "The icon to include in the button", displayName: "Icon" },
    { "name": "target", "type": ComponentPropertyType.Target, "required": true, "description": <p>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> to execute this button is clicked.</p>, displayName: "Target", category: "Target" },
    { "name": "parameters", "type": ComponentPropertyType.TargetParameters, "required": true, "description": "Parameters to provide to the target", displayName: "Parameters", category: "Parameters" },
    { "name": "toastOnSuccess", "type": ComponentPropertyType.Boolean, "required": false, "description": "Show a toast message when the action was successful.", displayName: "Toast on Success" },
    { "name": "toastOnFailure", "type": ComponentPropertyType.Boolean, "required": false, "description": "Show a toast message when the action failed.", displayName: "Toast on Error" },
    //{ "name": "refreshComponents", "type": ComponentPropertyType.Boolean, "required": false, "description": "Refresh components on the page after clicking this button.", displayName: "Refresh Components" },
]

export const NewPSUButton = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Button, PowerShellUniversal',
        "text": "Button",
        "type": "Button",
        "id": uuidv4(),
        "toastOnFailure": true,
        "toastOnSuccess": true
    }
}

export default function PSUButton(props) {

    const { inputObject, onSuccess } = props;
    const [loading, setLoading] = useState(false);

    const onClick = () => {
        if (!props.target) return;

        var options;
        var url;
        if (props.target.type === "api") {
            var parameters = props.target.parameters || [];
            if (inputObject) {
                parameters.push({
                    name: 'InputObject',
                    value: inputObject
                });
            }

            url = props.target.name;
            options = {
                method: 'POST',
                body: JSON.stringify(parameters),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }

        if (props.target.type === "script") {
            url = `/api/v1/script/path/${props.target.name}`;

            var scriptParameters = props.target.parameters || [];
            if (inputObject) {
                scriptParameters.push({
                    name: 'InputObject',
                    type: 'PSObject',
                    value: JSON.stringify(inputObject)
                });
            }

            options = {
                method: 'POST',
                body: JSON.stringify({
                    jobParameters: scriptParameters.map(p => {
                        return {
                            name: p.name,
                            type: p.type || 'System.Object',
                            value: p.value
                        }
                    }),
                    environment: props.target.environment,
                    credential: props.target.runAs,
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        }

        setLoading(true);

        fetch(url, options)
            .then(res => {
                setLoading(false);

                if (res.status !== 200) {
                    if (props.toastOnFailure)
                        message.error('Failed!');
                }
                else if (props.toastOnSuccess) {
                    message.success('Success!');
                }

                if (res.status === 200 && onSuccess) {
                    onSuccess();
                }

            })
            .catch(err => {
                if (props.toastOnFailure)
                    message.error('Failed!');
            });
    }

    return <Button loading={loading} onClick={onClick} icon={props.icon && React.createElement(Icons[props.icon])}>{props.text}</Button>
}