import { Identity } from "../../../types";
import { CustomSchema } from "./types";

export const identitySchema: CustomSchema<Identity>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    uniqe: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "roleName",
    label: "Role",
    component: "role",
    required: true,
  },
];
