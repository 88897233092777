import { convertFromAntTag } from "./convertFromAntTag";
import { hasTags, setTriggerEventName } from "./utils";
import { convertToAntTag } from "./convertToAntTag";
import React from "react";

export default function useNormalizeData() {
  const normalizedRecord = React.useCallback((record) => {
    if (record?.resourceInfo?.parent === "/tag" && record?.color?.hex) {
      return {
        ...record,
        color: record?.color?.hex,
      };
    }

    if (record?.resourceInfo?.parent === "/schedule") {
      return {
        ...record,
        environment: record?.environment === "Default" ? null : record?.environment,
        type: record?.oneTime
          ? "oneTime"
          : record?.continuous
            ? "continuous"
            : "simple",
      };
    }

    if (record?.resourceInfo?.parent === "/trigger") {
      return {
        ...record,
        eventType:
          typeof record?.eventType === "string"
            ? record?.eventType
            : setTriggerEventName(record?.eventType),
      };
    }

    if (record?.resourceInfo?.parent === "/variable") {
      return {
        ...record,
        secret: record?.vault ? true : false,
        variableType: record?.vault ? "secret" : "simple",
      };
    }

    if (record?.resourceInfo?.parent === "/endpoint") {
      if (hasTags(record)) {
        return {
          ...record,
          tag: record?.tag[0]?.label ? convertFromAntTag(record?.tag) : convertToAntTag(record?.tag),
          role: record?.authentication ? record?.role : null,
        };
      }
      return {
        ...record,
        role: record?.authentication ? record?.role : null,
      };
    }

    if (record?.resourceInfo?.parent === "/dashboard") {
      if (hasTags(record)) {
        return {
          ...record,
          tag: record?.tag[0]?.label ? convertFromAntTag(record?.tag) : convertToAntTag(record?.tag),
          autoStart: !record?.disableAutoStart,
          disableAutoStart: !record?.autoStart,
          environment: record?.environment === "Default" ? null : record?.environment,
          role: record?.authenticated ? record?.role : null,
          dashboardFramework: record.dashboardFramework?.name
            ? record.dashboardFramework?.id
            : { id: record.dashboardFramework },
        };
      }
      return {
        ...record,
        autoStart: !record?.disableAutoStart,
        disableAutoStart: !record?.autoStart,
        environment: record?.environment === "Default" ? null : record?.environment,
        role: record?.authenticated ? record?.role : null,
        dashboardFramework: record.dashboardFramework?.name
          ? record.dashboardFramework?.id
          : { id: record.dashboardFramework },
      };
    }

    if (record?.resourceInfo?.parent === "/publishedfolder") {
      return {
        ...record,
        role: record?.authentication ? record?.role : null,
      };
    }

    if (record?.resourceInfo?.parent === "/script") {
      if (hasTags(record)) {
        return {
          ...record,
          environment: record?.environment === "Default" ? null : record?.environment,
          tag: record?.tag[0]?.label
            ? convertFromAntTag(record?.tag)
            : convertToAntTag(record?.tag),
        };
      }
      return {
        ...record,
        environment: record?.environment === "Default" ? null : record?.environment,
      };
    }

    if (hasTags(record)) {
      return {
        ...record,
        tag: record?.tag[0]?.label
          ? convertFromAntTag(record?.tag)
          : convertToAntTag(record?.tag),
      };
    }

    return record;
  }, []);

  return normalizedRecord;
}
