import { Typography } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';

export const Props : Array<IComponentProperty>  = [
    { "name": "id", "type": ComponentPropertyType.String , "required": true, "description": "The ID for this typography", displayName: "Id" },
    { "name": "value", "type": ComponentPropertyType.String , "required": true, "description": "The text to display", displayName: "Text" },
    { "name": "code", "type": ComponentPropertyType.Boolean , "required": false, "description": "Display this text as code.", displayName: "Code" },
    { "name": "copyable", "type": ComponentPropertyType.Boolean , "required": false, "description": "Whether this text is copyable", displayName: "Copyable" },
    { "name": "textType", "type": ComponentPropertyType.Select , "required": false, "description": "The type of text to display.", displayName: "Type", options: [
        { value: null, text: "Default" },
        { value: "secondary", text: "Secondary" },
        { value: "success", text: "Success" },
        { value: "warning", text: "Warning" },
        { value: "danger", text: "Danger" }	
    ] },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Paragraph, PowerShellUniversal',
        "value": "Paragraph",
        "type": "Paragraph",
        "id": uuidv4(),
        "level": "1"
    }
}

export function Component(props) {
    return <Typography.Paragraph code={props.code} type={props.textType}>{props.value}</Typography.Paragraph>
}