import { Switch } from 'antd';
import { IComponentProperty, ComponentPropertyType } from '../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import { VariableContext } from '../VariableContext';

export const Props: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": true, "description": "The ID for this typography", displayName: "Id" },
    { "name": "checked", "type": ComponentPropertyType.Boolean, "required": false, "description": "The text to display", displayName: "Checked" },
    { "name": "variable", "type": ComponentPropertyType.Variable, "required": true, "description": "The text to display", displayName: "Variable" },
]

export const Constructor = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Switch, PowerShellUniversal',
        "value": "Switch",
        "type": "Switch",
        "id": uuidv4()
    }
}

export function Component(props) {
    return (
        <VariableContext.Consumer>
            {(context) => {
                return <Switch onChange={(e) => context.setVariable({
                    name: props.variable.name,
                    value: e.toString(),
                    session: props.variable.type === "session",
                    context: props.variable.type
                })}></Switch>
            }}
        </VariableContext.Consumer>
    )
}