import { Trigger } from "../../../types";
import { Field } from "./Form";
import { CustomSchema } from "./types";

export const triggerSchame: CustomSchema<Trigger>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    uniqe: true,
    tooltip: "Display name of the trigger"
  },
  {
    name: "triggerScript",
    label: "Trigger Script",
    component: "select",
    selectOptions: "script",
    required: true,
    uniqe: false,
    tooltip: "The script to trigger when the selected event happens."
  },
  {
    name: "eventType",
    label: "Event",
    component: "eventType",
    required: true,
    uniqe: false,
    tooltip: "The event that causes the trigger to run."
  },
  {
    name: "environment",
    label: "Environment",
    component: "environment",
    initialValue: "Default",
    tooltip: "The environment to run the triggered script in."
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.eventType !== curValues.eventType;
    },
    children: ({ getFieldValue }) => {
      let selectedEvent = getFieldValue("eventType");
      return selectedEvent?.startsWith("Dashboard") ? (
        <Field
          component="select"
          selectOptions="dashboard"
          name="dashboard"
          label="Dashboard"
          preserve={false}
          tooltip="The trigger will only happen when the event occurs with this dashboard."
        />
      ) : null;
    },
  },
  {
    required: true,
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.eventType !== curValues.eventType;
    },
    children: ({ getFieldValue }) => {
      let selectedEvent = getFieldValue("eventType");
      return selectedEvent?.startsWith("Job") ? (
        <Field
          component="select"
          selectOptions="script"
          name="script"
          label="Script"
          preserve={false}
          tooltip="The trigger will only happen when the event occurs with this script."
        />
      ) : null;
    },
  },
  {
    name: "delay",
    label: "Delay (Seconds)",
    component: "inputNumber",
    tooltip: "The number of seconds to delay before running the trigger script."
  },
];
