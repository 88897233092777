import React from "react";
import { Button, Checkbox, Form, Input, Popconfirm, Select, Tabs, Typography } from "antd";
import { DeleteOutlined } from '@ant-design/icons';

export default function FormFields(props) {
    const [fieldNames, setFieldNames] = React.useState(props.component.target ? props.component.target.fields.map(x => x.name) : []);
    const [types, setTypes] = React.useState(props.component.target ? props.component.target.fields.map(x => x.type) : []);

    const setName = (index, name) => {
        setFieldNames({ ...fieldNames, [index]: name });
    }

    const setType = (index, type) => {
        setTypes({ ...types, [index]: type });
    }

    const formField = (field, remove) => {
        return (
            <Tabs.TabPane key={field.key} tab={fieldNames[field.name]} closable={false}  >
                <Form.Item
                    label="Name"
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    rules={[{ required: true, message: 'Missing name' }]}
                >
                    <Input onChange={(e) => setName(field.name, e.target.value)} />
                </Form.Item>
                <Form.Item
                    label="Type"
                    name={[field.name, 'type']}
                    fieldKey={[field.fieldKey, 'type']}
                    rules={[{ required: true, message: 'Missing type' }]}
                >
                    <Select onChange={e => setType(field.name, e.toString())}>
                        <Select.Option value="checkbox">Checkbox</Select.Option>
                        <Select.Option value="date">Date</Select.Option>
                        <Select.Option value="hidden">Hidden</Select.Option>
                        <Select.Option value="number">Number</Select.Option>
                        <Select.Option value="select">Select</Select.Option>
                        <Select.Option value="switch">Switch</Select.Option>
                        <Select.Option value="textbox">Textbox</Select.Option>
                        <Select.Option value="time">Time</Select.Option>
                        <Select.Option value="rating">Rating</Select.Option>
                    </Select>
                </Form.Item>
                {types[field.name] === 'select' && (
                    <Form.Item
                        label="Available Values"
                        name={[field.name, 'values']}
                        fieldKey={[field.fieldKey, 'values']}>
                        <Select mode="tags" />
                    </Form.Item>
                )}
                <Form.Item
                    label="Default Value"
                    name={[field.name, 'value']}
                    fieldKey={[field.fieldKey, 'value']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tooltip"
                    tooltip="Tooltip for the field"
                    name={[field.name, 'tooltip']}
                    fieldKey={[field.fieldKey, 'tooltip']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Help"
                    tooltip="Help text for the field"
                    name={[field.name, 'help']}
                    fieldKey={[field.fieldKey, 'help']}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Required"
                    name={[field.name, 'required']}
                    fieldKey={[field.fieldKey, 'required']}
                    valuePropName="checked"
                >
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item label="Remove Field">
                    <Popconfirm title="Remove field?" onConfirm={() => remove(field.name)}>
                        <Button danger icon={<DeleteOutlined />}  ></Button>
                    </Popconfirm>
                </Form.Item>
            </Tabs.TabPane>
        )
    };

    const onEdit = (action, add, fields) => {
        if (action === "add") {
            add({
                key: fields.length + 1
            })
        }
    }

    return (
        <>
            <Form.List name={["target", "fields"]}>
                {(fields, { add, remove }) => (
                    <>
                        <Typography.Text strong>Fields</Typography.Text>
                        <Typography.Paragraph>Fields the user can enter when submitting the form. These fields will be passed as variables to the target.</Typography.Paragraph>
                        <Tabs type="editable-card" onEdit={(targetKey, action) => onEdit(action, add, remove)}>
                            {fields.map(field => formField(field, remove))}
                        </Tabs>
                    </>
                )}
            </Form.List>
        </>
    )
}
