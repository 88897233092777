import React from "react";
import { Form, Select, Typography } from "antd";
import { useQuery } from "react-query3";
import { Endpoint, Environment, Script } from "./../../../types";
import { VariableContext } from "../VariableContext";

export default function PSUTarget(props) {
    const { data, isLoading } = useQuery<Script[]>("/script");
    const { data: apis, isLoading: apisLoading } = useQuery<Endpoint[]>("/endpoint");
    const { data: environments, isLoading: environmentLoading } = useQuery<Environment[]>("/environment");

    const [dataSourceType, setDataSourceType] = React.useState(props.component.dataSourceType || "script");
    const [searchText, setSearchText] = React.useState("");

    return (
        <VariableContext.Consumer>
            {(context) => (
                <>
                    <Typography.Text strong>Target</Typography.Text>
                    <Typography.Paragraph>{props.description}</Typography.Paragraph>

                    <Form.Item
                        label="Type"
                        name={["target", "type"]}
                        rules={[{ required: true }]}
                    >
                        <Select defaultActiveFirstOption={dataSourceType} onChange={(value) => setDataSourceType(value)}>
                            <Select.Option key="api" value="api">API</Select.Option>
                            <Select.Option key="script" value="script">Script</Select.Option>
                        </Select>
                    </Form.Item>

                    {dataSourceType === "script" ? <>
                        <Form.Item
                            label="Script"
                            name={["target", "name"]}
                            rules={[{ required: true }]}
                        >
                            <Select loading={isLoading} onSearch={(val) => setSearchText(val)} showSearch>
                                {searchText !== '' && <Select.Option key="search" value={searchText}>{searchText}</Select.Option>}
                                {data?.map((script) => (
                                    <Select.Option key={script.fullPath} value={script.fullPath}>{script.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Run As"
                            name={["target", "runAs"]}
                        >
                            <Select>
                                <Select.Option value={null}>Default</Select.Option>
                                {context.credentials?.map((variable) => (
                                    <Select.Option key={variable.name} value={variable.name}>{variable.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Environment"
                            name={["target", "environment"]}
                        >
                            <Select loading={environmentLoading}>
                                <Select.Option value={null}>Default</Select.Option>
                                {environments?.map((env) => (
                                    <Select.Option key={env.name} value={env.name}>{env.name}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </> : <></>}

                    {dataSourceType === "api" ? <>
                        <Form.Item
                            label="API"
                            name={["target", "name"]}
                            rules={[{ required: true }]}
                            tooltip="The API will be called with an HTTP POST"
                        >
                            <Select loading={apisLoading} onSearch={(val) => setSearchText(val)} showSearch>
                                {searchText !== '' && <Select.Option key="search" value={searchText}>{searchText}</Select.Option>}
                                {apis?.filter(x => x.method === 'POST').map((endpoint) => (
                                    <Select.Option key={endpoint.id} value={endpoint.url}>{`${endpoint.url}`}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </> : <></>}
                </>
            )}
        </VariableContext.Consumer>
    )
}
