import React, { CSSProperties } from "react";
import { Space } from "antd";


export default function TableTitle({ children, style }: { children: React.ReactNode, style: CSSProperties}) {
  return (
    <Space
      style={style}
      align="center"
    >
      {children}
    </Space>
  );
}
