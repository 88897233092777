import dayjs from "dayjs";

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
export const dateSort = (dateA: string, dateB: string) => dayjs(dateA).diff(dayjs(dateB));

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
export const defaultSort = (a: number | string, b: number | string) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

export const stringSorter = (a: string,b: string) => {
    return String(a)?.localeCompare(String(b))
}

export const Sorter = {
  DEFAULT: defaultSort,
  DATE: dateSort,
  WORD: stringSorter
};
