import React from "react";
import { parseJobLogNoTimestamp } from "components/utils/utils";
import { Job, JobLog, JobStatus, JobStreamOutput } from "types";
import { useQuery } from "react-query3";
import { Button, Card, message, Tooltip } from "antd";
import { CopyFilled, RollbackOutlined } from "@ant-design/icons";
import useClipboard from "use-clipboard-hook";

export default function SimpleJobOutput({ refetchInterval, job, canReset, reset }: { refetchInterval: false | number, job: Job, canReset?: boolean, reset?: () => void }) {
    const { data, isLoading } = useQuery<JobLog>(
        `/job/${job?.id}/log`, {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        refetchInterval
    }
    );

    function getLogContent() {
        if (isLoading) return null;
        try {
            let logContent: JobStreamOutput[] = JSON.parse(data.log)?.map((entry: JobStreamOutput) => ({ ...entry, JobId: data?.id }));
            return logContent;
        } catch (error) {
            //console.error("Error parsing Job Log");
        }
    }

    const content = getLogContent();
    let jobLogContent = "";
    if ((job?.status === JobStatus.Completed || job?.status === JobStatus.Failed) && content == null && !isLoading) {
        jobLogContent = "# This job produced no output"
    } else {
        jobLogContent = parseJobLogNoTimestamp(getLogContent());
    }

    if (jobLogContent === "# Waiting for job data...") {
        jobLogContent = "";
    }

    const { copy } = useClipboard({
        onSuccess: () => message.success(`Copied.`),
    });

    const onCopy = () => {
        copy(jobLogContent);
    }

    return (
        <Card title="" extra={
            <>
                <Tooltip title="Copy">
                    <Button
                        type="text"
                        icon={<CopyFilled />}
                        onClick={() => onCopy()}
                    />
                </Tooltip>
                {canReset && <Tooltip title="Go Back">
                    <Button onClick={reset} icon={<RollbackOutlined />} type="text"></Button>
                </Tooltip>}
            </>
        }>

            <pre style={{ maxHeight: '25ch' }} >{jobLogContent}</pre>
        </ Card>

    );
}
