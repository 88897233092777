import { Page } from "../../../types";
import { CustomSchema } from "./types";
import { Field } from "./Form";
import queryClient from "components/utils/queryClient";

export const pageSchema: CustomSchema<Page>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "url",
    label: "URL",
    component: "input",
    required: false,
    tooltip: "Optional URL. If not specified, the name is the URL."
  },
  {
    name: "description",
    label: "Description",
    component: "input"
  },
  {
    name: "authenticated",
    label: "Authentication",
    component: "authSwitch",
    valuePropName: 'checked',
    required: false,
    uniqe: false,
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.authenticated !== curValues.authenticated || curValues.authenticated === true
    },
    children: ({ getFieldValue }) => {
      const name = getFieldValue("name")
      let defaultRoles = queryClient.getQueryData<Page[]>(`/page`)?.find(page => page?.name === name)?.roles
      return getFieldValue("authenticated") === true ? (
        <Field
          component="roleTags"
          name="roles"
          label="Roles"
          preserve={false}
          required={true}
          initialValue={defaultRoles || null}
          dependencies={['authenticated']}
          uniqe={false}
        />
      ) : null;
    },
  },
];
