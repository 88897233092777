import React from 'react';
import { Form, Input, Modal, Tabs } from 'antd';
import { GetProps } from './Component';
import { ComponentPropertyType, IComponentProperty } from './ComponentProperty';
import DataSourceSelector from './properties/dataSource';
import Number from './properties/number';
import Checkbox from './properties/checkbox';
import PSUTarget from './properties/target';
import PSUSelect from './properties/select';
import PSUIcon from './properties/icon';
import TableColumns from './properties/tableColumns';
import FormFields from './properties/formFields';
import TargetParameters from './properties/targetParameters';
import Variable from './properties/variable';

const { TabPane } = Tabs;

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function ComponentProperties({ component, form }) {

  const componentProps = GetProps(component);

  const renderField = (prop: IComponentProperty) => {
    switch (prop.type) {
      case ComponentPropertyType.TableColumns:
        return <TableColumns key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.Target:
        return <PSUTarget key={prop.name} {...prop} component={component} />
      case ComponentPropertyType.Boolean:
        return <Checkbox key={prop.name} {...prop} />
      case ComponentPropertyType.Number:
        return <Number key={prop.name} {...prop} />
      case ComponentPropertyType.Select:
        return <PSUSelect key={prop.name} {...prop} />
      case ComponentPropertyType.Icon:
        return <PSUIcon key={prop.name} {...prop} />
      case ComponentPropertyType.String:
        return (
          <Form.Item
            key={prop.name}
            label={prop.displayName}
            name={prop.name}
            rules={[{ required: prop.required }]}
            tooltip={prop.description}
          >
            <Input />
          </Form.Item>
        )
      case ComponentPropertyType.DataSource:
        return <DataSourceSelector key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.FormFields:
        return <FormFields key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.TargetParameters:
        return <TargetParameters key={prop.name} {...prop} component={component} form={form} />
      case ComponentPropertyType.Variable:
        return <Variable key={prop.name} {...prop} component={component} form={form} />

    }
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const requiredFields = componentProps.filter(m => m.required && !m.category).map(renderField);
  const grouped = groupBy(componentProps, component => component.category);
  const categories = componentProps.filter(m => m.category).map(m => m.category).filter(onlyUnique);
  const optionalFields = componentProps.filter(m => !m.required && !m.category).map(renderField);

  return (
    <Tabs defaultActiveKey="1">
      {requiredFields.length > 0 &&
        <TabPane tab="Required" key="1">
          {requiredFields}
        </TabPane>
      }
      {
        categories.map(category => {
          return <TabPane tab={category} key={category}>
            {grouped.get(category).map(renderField)}
          </TabPane>
        })
      }
      {optionalFields.length > 0 &&
        <TabPane tab="Optional" key="2">
          {optionalFields}
        </TabPane>
      }
    </Tabs>
  )
}

export default function ComponentPropertiesModal(props) {
  const [form] = Form.useForm();
  const { visible, setVisible, component, setComponent } = props;

  const onFinish = (values: any) => {
    var updatedComponent = { ...component, ...values };
    setComponent(updatedComponent);
    setVisible(false);
  }

  const submit = () => {
    form.validateFields()
      .then(values => {
        form.resetFields();
        onFinish(values);
      })
  }

  return (
    <>
      <Modal
        title="Properties"
        width="50vw"
        closable={false}
        maskClosable={false}
        onCancel={() => setVisible(false)}
        onOk={submit}
        okText="Save"
        visible={visible}
      >
        {props.description}
        <Form name="properties" initialValues={component} onFinish={onFinish} form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          layout="horizontal"
        >
          <ComponentProperties component={component} form={form} />
        </Form>
      </Modal>
    </>
  );
};
